import { AddIcon, ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../../api";

import {
  archiveVacataire,
  createVacataire,
  getVacataire,
  getVacataireArchived,
  relanceVacataire,
  updateVacataire,
} from "../../api/ApiVacataire";
import { Vacataire } from "../../utils/types";
import { EmptyState } from "../_shared/state/EmptyState";
import { ArchiveVacataireModal } from "./modal/ArchiveVacataire";
import CreateEditVacataire from "./modal/CreateEditVacataire";
import { RelanceVacataireModal } from "./modal/RelanceVacataireModal";
import { VacataireTable } from "./VacataireTable";

export const ManageVacataire = ({
  archiveState,
  activeTab,
}: {
  archiveState: boolean;
  activeTab: number;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Utilisez useQuery à la place

  const fetchVacataire = async () => {
    const data = archiveState
      ? await getVacataireArchived()
      : await getVacataire();
    return data;
  };
  const toast = useToast();

  const {
    data: vacataire,
    isError,
    error,
    refetch,
  } = useQuery<Vacataire[], Error>(
    ["vacataire", archiveState],
    fetchVacataire,
    {
      enabled: activeTab === (archiveState ? 1 : 0),
    }
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const imagePath = "/images/empty_state.png"; // Remplacez "nom_de_votre_image.png" par le nom réel de votre fichier image
  const [initialTabIndex, setInitialTabIndex] = useState(0);

  const [, setIsModalOpenEdit] = useState(false);

  const [editing, setEditing] = useState(false);
  const [selectedVacataire, setSelectedVacataire] = useState<
    Partial<Vacataire> | undefined
  >(undefined);

  const isDataEmpty =
    vacataire?.length === 0 || vacataire?.length === undefined;

  const [updateCount, setUpdateCount] = useState(0);

  const {
    isOpen: isArchiveModalOpen,
    onOpen: onOpenArchiveModal,
    onClose: onCloseArchiveModal,
  } = useDisclosure();

  const {
    isOpen: isRelanceModalOpen,
    onOpen: onOpenRelanceModal,
    onClose: onCloseRelanceModal,
  } = useDisclosure();

  const handleArchiveVacataire = async () => {
    if (!selectedVacataire) return;
    try {
      console.log(selectedVacataire);
      await archiveVacataire(selectedVacataire);
      onCloseArchiveModal();
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleRelanceModalOpen = async () => {
    if (!selectedVacataire) return;
    try {
      console.log(selectedVacataire);
      if (selectedVacataire._id) {
        await relanceVacataire(selectedVacataire?._id); // Remplacez par l'ID approprié selon votre structure de données
      }
      onCloseRelanceModal();
      toast({
        title: "Succès.",
        description: "La relance a été envoyée avec succès.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      refetch(); // si vous avez besoin de rafraîchir les données après l'envoi
    } catch (error) {
      toast({
        title: "Erreur.",
        description: "Une erreur est survenue lors de l'envoi de la relance.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error(error);
    }
  };

  const openArchiveModal = (vacataire: Vacataire) => {
    setSelectedVacataire(vacataire); // Définir le vacataire sélectionné
    onOpenArchiveModal();
  };

  const handleSubmitVacataire = (data: Vacataire) => {
    try {
      if (selectedVacataire) {
        let updatedVacataire = { ...data };
        delete updatedVacataire.photo;
        delete updatedVacataire.contrats;
        delete updatedVacataire.pay_slip;
        delete updatedVacataire.dpae;
        delete updatedVacataire.autorisations;
        delete updatedVacataire.rib;
        delete updatedVacataire.identity_card;
        delete updatedVacataire.identity_card_verso;
        delete updatedVacataire.vital_card;
        delete updatedVacataire.proof_of_address;
        delete updatedVacataire.criminal_record;
        delete updatedVacataire.minor_work_authorisation;
        delete updatedVacataire.minor_identity_card;
        delete updatedVacataire.minor_identity_card_verso;
        delete updatedVacataire.security_card;
        delete updatedVacataire.security_degree;

        updateMutation.mutate(updatedVacataire);
      } else {
        // En mode création
        createMutation.mutate(data);
      }
      onClose(); // Fermer la modale après une soumission réussie
    } catch (error) {
      console.error(error);
    }
  };

  const createMutation = useMutation(createVacataire, {
    onSuccess: () => {
      toast({
        title: "Succès.",
        description: "Le vacataire a été créé et un email a été envoyé.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      refetch();
    },
    onError: (error) => {
      toast({
        title: "Erreur.",
        description:
          "Une erreur est survenue lors de la création du vacataire.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error(error);
    },
  });

  const updateMutation = useMutation(updateVacataire, {
    onSuccess: () => {
      toast({
        title: "Succès.",
        description: "Le vacataire a été mis à jour.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      refetch();
    },
    onError: (error) => {
      toast({
        title: "Erreur.",
        description:
          "Une erreur est survenue lors de la mise à jour du vacataire.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error(error);
    },
  });

  const convertTimestampToDate = (timestamp: number) => {
    if (!timestamp) return "";
    const date = new Date(timestamp * 1000); // Convertir en millisecondes
    return date.toLocaleDateString(); // Format local de la date
  };

  const openCreateVacataire = () => {
    setSelectedVacataire(undefined); // Créer un nouveau vacataire, donc pas de valeurs par défaut
    onOpen();
  };

  const openEditVacataire = (data: Vacataire, initialTabIndex?: number) => {
    setSelectedVacataire(data);
    setInitialTabIndex(initialTabIndex ?? 0); // Si initialTabIndex n'est pas spécifié, on le définit à 0 par défaut
    onOpen();
  };

  const openRelanceMail = (data: Vacataire) => {
    setSelectedVacataire(data);
    setInitialTabIndex(initialTabIndex ?? 0); // Si initialTabIndex n'est pas spécifié, on le définit à 0 par défaut
    onOpenRelanceModal();
  };

  function handleSubmit(data: Vacataire): void {
    throw new Error("Function not implemented.");
  }

  //Export
  const exportPDF = () => {
    // Votre logique d'exportation PDF ici
    if (vacataire) {
      const doc = new jsPDF();
      autoTable(doc, { html: "#my-table-id" });
      doc.save("vacataires.pdf");
    }
  };

  const exportCSV = () => {
    // Votre logique d'exportation CSV ici
    if (vacataire) {
      const modifiedVacataire = vacataire.map((item) => {
        // Ajouter toutes les colonnes avec des valeurs vides
        return {
          id: item.id || "",
          serial_number: item.serial_number || "",
          name: item.name || "",
          firstname: item.firstname || "",
          mail: item.mail || "",
          tel: item.tel || "",
          gender: item.gender || "",
          clothes: item.clothes || {},
          address: item.address || {},
          birth: item.birth || {},
          security_number: item.security_number || "",
          rib: item.rib || "",
          stranger: item.stranger || false,
          legal_resp: item.legal_resp || {},
          nationality: item.nationality || "",
          urgence_call_tel: item.urgence_contact?.urgence_num || "",
          urgence_call_name: item.urgence_contact?.urgence_name || "",
          legal_stranger: item.legal_stranger || {},
          archived: item.archived || false,
          signature: item.signature || "",
        };
      });
      const csv = Papa.unparse(modifiedVacataire);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "vacataires.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const convertGenderToCivility = (gender: string) => {
    if (gender === "M") {
      return "Monsieur";
    } else if (gender === "F") {
      return "Madame";
    } else {
      return "";
    }
  };

  const exportExcel = async () => {
    if (vacataire) {
      const modifiedVacataire = vacataire.map((item) => ({
        matricule: item.serial_number || "",
        matricule_hospitality: item.serial_number_hospitality || "",
        nom: item.name || "",
        prenom: item.firstname || "",
        email: item.mail || "",
        telephone: item.tel || "",
        civilite: item.gender ? convertGenderToCivility(item.gender) : "",
        etranger: item.stranger ? "Oui" : "Non",
        mineur: item.isMinor ? "Oui" : "Non",
        profil_complet: item.profileComplete ? "Oui" : "Non",
        num_adresse: item.address ? item.address.num || "" : "",
        rue_adresse: item.address ? item.address.street || "" : "",
        CP_adresse: item.address ? item.address.zip_code || "" : "",
        ville_adresse_: item.address ? item.address.city || "" : "",
        pays_adresse_: item.address ? item.address.country || "" : "",
        date_de_naissance: item.birth
          ? `${convertTimestampToDate(item.birth.date_of_birth)}`
          : "",
        ville_de_naissance: item.birth ? `${item.birth.city}` : "",
        departement_de_naissance: item.birth ? `${item.birth.department}` : "",
        numero_de_securite: item.security_number || "",
        rib: item.iban || "",
        bic: item.bic || "",
        responsable_legal: item.legal_resp
          ? `${item.legal_resp.resp_firstname}, ${item.legal_resp.resp_name}`
          : "",
        nationalite: item.nationality || "",
        contact_urgence: item.urgence_contact
          ? `${item.urgence_contact.urgence_firstname}, ${item.urgence_contact.urgence_name}`
          : "",
        quelques_match: item.some_match ? "Oui" : "Non",
        toutes_saisons: item.all_season ? "Oui" : "Non",
        securite: item.security ? "Oui" : "Non",
        siap: item.siap ? "Oui" : "Non",
        accreditation: item.accreditation ? "Oui" : "Non",
        siap2: item.siap2 ? "Oui" : "Non",
        siap3: item.siap3 ? "Oui" : "Non",
        delivrance_diplome: item.diplome_deliverance || "",
        fin_diplome: item.end_diplome_deliverance || "",
        numero_diplome: item.number_diplome || "",
      }));

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Vacataires");

      worksheet.columns = Object.keys(modifiedVacataire[0]).map((key) => ({
        header: key,
        key: key,
        width: key === "rib" || key === "bic" ? 40 : 20,
      }));

      modifiedVacataire.forEach((vacataire) => {
        worksheet.addRow(vacataire);
      });

      // Génération du buffer
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Création d'un lien de téléchargement
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "vacataires.xlsx";

      // Déclenchement du téléchargement
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const exportRecap = async () => {
    try {
      const response = await fetch(
        `${API_URL}/zones-vacataire/all-vacataires/stats`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const vacataireStats = await response.json();

      if (vacataireStats && vacataireStats.length > 0) {
        // Trier les vacataires par ordre décroissant de participation
        const sortedVacataireStats = vacataireStats.sort(
          (a: any, b: any) => b.eventsCount - a.eventsCount
        );

        const modifiedVacataireStats = sortedVacataireStats.map(
          (item: any) => ({
            Nom: item.lastname || "",
            Prenom: item.firstname || "",
            Nombre_de_participations: item.eventsCount || 0,
            Email: item.mail || "",
            Telephone: item.tel || "",
            Date_de_naissance: item.date_of_birth
              ? `${convertTimestampToDate(item.date_of_birth)}`
              : "",
          })
        );

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Récapitulatif Vacataires");

        worksheet.columns = Object.keys(modifiedVacataireStats[0]).map(
          (key) => ({
            header: key.replace(/_/g, " "), // Pour rendre les en-têtes plus lisibles
            key: key,
            width: key === "Email" || key === "Telephone" ? 30 : 20,
          })
        );

        modifiedVacataireStats.forEach((vacataire: any) => {
          worksheet.addRow(vacataire);
        });

        // Génération du buffer
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Création d'un lien de téléchargement
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "recap_vacataires_2023-2024.xlsx";

        // Déclenchement du téléchargement
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Erreur lors de l'exportation du récapitulatif :", error);
    }
  };

  return (
    <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 2 }}>
      <Box>
        <Stack>
          <Box>
            <Box py="12">
              <Flex justify="space-between" align="center" py="1">
                <Heading
                  size={useBreakpointValue({ base: "xs", md: "sm" })}
                  color="primary"
                >
                  {archiveState
                    ? "Vacataires archivés"
                    : "Gestion des vacataires"}
                </Heading>

                <Stack isInline spacing={2}>
                  <Button
                    leftIcon={<AddIcon />}
                    variant={"outline"}
                    fontWeight="medium"
                    borderColor="primary"
                    color="primary"
                    onClick={openCreateVacataire}
                  >
                    Nouveau vacataire
                  </Button>

                  {vacataire && (
                    <Menu>
                      <MenuButton
                        as={Button}
                        color="primary"
                        rightIcon={<ChevronDownIcon />}
                      ></MenuButton>
                      <MenuList>
                        <MenuItem icon={<AddIcon />} onClick={exportExcel}>
                          Tableau de vacataire
                        </MenuItem>
                        <MenuItem icon={<DownloadIcon />} onClick={exportRecap}>
                          Récapitulatif 2023-2024
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </Stack>
              </Flex>
              <Text color="muted">
                {archiveState
                  ? "Sur cette page gérez vos différents vacataires archivés"
                  : "Sur cette page gérez vos différents vacataires"}
              </Text>
            </Box>

            <div></div>
            <Box
              boxShadow={{ base: "xlg", md: "xlg" }}
              borderRadius={{ base: "none", md: "lg" }}
            >
              <Stack spacing="5">
                {isDataEmpty ? (
                  <EmptyState
                    onButtonClick={openCreateVacataire}
                    imagePath={imagePath}
                    title="Aucun vacataire trouvé"
                    description="Il semble que vous n'ayez pas encore de vacataire. Créez-en un pour commencer à profiter des fonctionnalités de l'application."
                    textButton="Créer un nouveau vacataire"
                  />
                ) : (
                  <VacataireTable
                    data={vacataire ?? []}
                    openArchiveModal={openArchiveModal}
                    openEditVacataire={openEditVacataire}
                    openRelanceMail={openRelanceMail}
                    isArchiveTab={archiveState}
                  />
                )}
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Box>
      <CreateEditVacataire
        selectedVacataire={selectedVacataire}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmitVacataire}
        initialTabIndex={initialTabIndex}
      />{" "}
      <ArchiveVacataireModal
        isOpen={isArchiveModalOpen}
        onClose={onCloseArchiveModal}
        onConfirm={handleArchiveVacataire}
        vacataire={selectedVacataire}
      />
      <RelanceVacataireModal
        isOpen={isRelanceModalOpen}
        onClose={onCloseRelanceModal}
        onConfirm={handleRelanceModalOpen}
        vacataire={selectedVacataire}
      />
    </Container>
  );
};
