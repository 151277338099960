import { CloseIcon, Icon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { FaBlackTie, FaFileContract, FaWarehouse } from "react-icons/fa";
import { FiHelpCircle, FiMenu, FiSearch, FiSettings } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DEPOSITS_PAGE,
  EVENTS_PAGE,
  TEMP_WORKERS_PAGE,
} from "../../../utils/navigation/endpoints";
import { NavButton } from "../interaction/NavButton";
import { LogoEstac } from "../logo";
import { Notification } from "../notification/Notification";
import { MenuAvatar } from "./MenuAvatar";

export const SideNavProtected = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Ajoutez cette ligne

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleNavigateSidenav = (page: string) => {
    navigate(page);
  };
  const navContent = (
    <Stack justify="space-between">
      <Stack spacing={{ base: "5", sm: "6" }} shouldWrapChildren>
        <LogoEstac />
        <InputGroup visibility="hidden">
          <InputLeftElement pointerEvents="none">
            <Icon as={FiSearch} color="on-accent" boxSize="5" />
          </InputLeftElement>
          <Input
            placeholder="Rechercher ..."
            variant="filled"
            colorScheme="blue"
          />
        </InputGroup>
        <Stack spacing="4">
          <NavButton
            label="Évènements"
            onClick={() => handleNavigateSidenav(EVENTS_PAGE)}
            aria-current={
              location.pathname === EVENTS_PAGE ? "page" : undefined
            }
            icon={FaWarehouse}
          />
          <NavButton
            label="Vacataires"
            icon={FaBlackTie}
            aria-current={
              location.pathname === TEMP_WORKERS_PAGE ? "page" : undefined
            }
            onClick={() => handleNavigateSidenav(TEMP_WORKERS_PAGE)}
          />
          <NavButton
            label="Dépôts"
            icon={FaFileContract}
            aria-current={
              location.pathname === DEPOSITS_PAGE ? "page" : undefined
            }
            onClick={() => handleNavigateSidenav(DEPOSITS_PAGE)}
          />
        </Stack>
      </Stack>
      <Stack spacing={{ base: "5", sm: "6" }}>
        <Stack spacing="1">
          <NavButton label="Aide" icon={FiHelpCircle} />
          <NavButton label="Options" icon={FiSettings} />
        </Stack>

        <Box bg="secondary" px="4" py="5" borderRadius="lg">
          <Notification />
        </Box>

        <Divider borderColor="bg-accent-subtle" />
        <MenuAvatar />
      </Stack>
    </Stack>
  );

  return (
    <Flex as="section" minH="100vh" bg="primary">
      <IconButton
        visibility={{ base: "visible", md: "hidden" }} // Utilisez 'visibility' à la place de 'display'
        variant="outline"
        colorScheme="teal"
        aria-label="Send email"
        position="fixed"
        icon={<FiMenu />}
        top="2"
        left="2"
        onClick={onOpen}
      />
      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay>
          <DrawerContent bg="primary" color="on-accent">
            <DrawerBody p="4">
              {navContent}
              <Box textAlign="right">
                <IconButton
                  variant="outline"
                  aria-label="Fermer le menu"
                  icon={<CloseIcon />}
                  size="sm"
                  onClick={onClose}
                  position="absolute"
                  top={2}
                  right={2}
                />
              </Box>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <Flex
        flex="1"
        bg="bg-accent"
        color="on-accent"
        maxW={{ base: "full", md: "xs" }}
        py={{ base: "6", md: "8" }}
        px={{ base: "4", md: "6" }}
        display={{ base: "none", md: "flex" }}
      >
        {navContent}
      </Flex>
      <div></div>
      <p></p>
    </Flex>
  );
};
