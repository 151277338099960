import {
  Box,
  Button,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  FaBriefcase,
  FaFileContract,
  FaFileUpload,
  FaQuestionCircle,
  FaShirtsinbulk,
  FaUser,
} from "react-icons/fa";
import { AuthContext } from "../../../utils/context";
import { Vacataire } from "../../../utils/types";
import { Roles } from "../../../utils/types/roles.interface";
import VacataireSchema from "../../../utils/validation/ValidationVacataireSchema";
import CombinedComponent from "../CombinedComponent";
import PaySlipComponent from "../PaySlipComponent";

import InformationsForm from "./form/InformationsForm";
import InfosPerso from "./form/Perso";
import DetailsProLegal from "./form/ProLegal";
import DocumentUpload from "./form/UploadDocVacataire";

interface MyFormProps {
  defaultValues?: Partial<Vacataire>; // Permet de passer des valeurs par défaut pour l'édition
}

interface CreateEditVacataireProps {
  selectedVacataire?: Partial<Vacataire> | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Partial<Vacataire>) => void;
  initialTabIndex?: number;
}

const CreateEditVacataire: React.FC<CreateEditVacataireProps> = ({
  selectedVacataire,
  isOpen,
  onClose,
  onSubmit,
  initialTabIndex = 0,
}) => {
  const methods = useForm({
    resolver: yupResolver(VacataireSchema as any),
    defaultValues: selectedVacataire || {}, // si selectedVacataire est null, utiliser un objet vide
    mode: "onBlur", // vérifier les erreurs lors du blur
    shouldUnregister: false,
  });
  const { userRole } = useContext(AuthContext);
  const [contrats, setContrats] = useState<string[]>(
    selectedVacataire?.contrats || []
  );

  const [dpae, setDpae] = useState<string[]>(selectedVacataire?.dpae || []);

  const [isMinor, setIsMinor] = useState(false);

  const securityChecked = methods.watch("security");
  const siapChecked = methods.watch("siap");
  const siap2Checked = methods.watch("siap2");
  const siap3Checked = methods.watch("siap3");

  const [tabIndex, setTabIndex] = useState(initialTabIndex);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const handleNext = () => {
    setTabIndex((prevTabIndex) => prevTabIndex + 1);
  };

  useEffect(() => {
    methods.reset(selectedVacataire || {});
  }, [selectedVacataire, methods]);

  useEffect(() => {
    if (selectedVacataire?.contrats) {
      setContrats(selectedVacataire.contrats);
    }
    if (selectedVacataire?.dpae) {
      setDpae(selectedVacataire.dpae);
    }
  }, [selectedVacataire]);

  useEffect(() => {
    setTabIndex(initialTabIndex);
  }, [initialTabIndex]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        mx={{ base: 4, md: 15 }}
        maxW={{ base: "95vw", md: "65vw" }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit, (errors) => {
              console.log(errors); // Affiche les erreurs dans la console
            })}
          >
            {" "}
            <ModalHeader color="primary">
              {selectedVacataire
                ? "Modifier le vacataire"
                : "Créer un vacataire"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Tabs index={tabIndex} onChange={handleTabsChange}>
                <TabList>
                  <Tab flex="1" gap={2}>
                    <Box as={FaUser} /> {/* Icône d'utilisateur */}
                    Identité
                  </Tab>
                  <Tab flex="1" gap={2}>
                    <Box as={FaBriefcase} /> {/* Icône de porte-documents */}
                    Adresse et coordonnées bancaires
                  </Tab>
                  <Tab flex="1" gap={2}>
                    <Box as={FaFileUpload} /> {/* Icône d'upload de fichier */}
                    Dépôt de documents
                  </Tab>
                  <Tab flex="1" gap={2}>
                    <Box as={FaFileContract} />{" "}
                    {/* Icône d'upload de fichier */}
                    Contrats & DPAE
                  </Tab>
                  <Tab flex="1" gap={2}>
                    <Box as={FaShirtsinbulk} />{" "}
                    {/* Icône d'upload de fichier */}
                    Renseignements
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Text
                      fontSize="1.5xl"
                      fontFamily="montserrat"
                      fontWeight="bold"
                    >
                      Identité
                    </Text>
                    <InfosPerso
                      isMinor={isMinor}
                      setIsMinor={setIsMinor}
                      securityChecked={securityChecked}
                      siapChecked={siapChecked}
                      siap2Checked={siap2Checked}
                      siap3Checked={siap3Checked}
                    />
                  </TabPanel>
                  <TabPanel>
                    <DetailsProLegal />
                  </TabPanel>
                  <TabPanel>
                    {selectedVacataire && selectedVacataire._id ? (
                      <div>
                        <Grid
                          templateColumns={{
                            base: "1fr",
                            md: "1fr 1fr",
                            lg: "1fr 1fr",
                          }}
                          gap={8}
                          mt={4}
                        >
                          <DocumentUpload
                            name="photo"
                            label="Photo"
                            id={selectedVacataire._id}
                          />
                          <DocumentUpload
                            name="rib"
                            label="RIB"
                            id={selectedVacataire._id}
                          />
                          <DocumentUpload
                            name="identity_card"
                            label="Recto carte d'identité"
                            id={selectedVacataire._id}
                          />
                          <DocumentUpload
                            name="identity_card_verso"
                            label="Verso carte d'identité"
                            id={selectedVacataire._id}
                          />
                          <DocumentUpload
                            name="vital_card"
                            label="Carte vitale"
                            id={selectedVacataire._id}
                          />

                          <DocumentUpload
                            name="proof_of_address"
                            label="Justificatif de domicile"
                            id={selectedVacataire._id}
                          />
                          <DocumentUpload
                            name="criminal_record"
                            label="Casier judiciaire"
                            id={selectedVacataire._id}
                          />

                          {(isMinor == true ||
                            !!selectedVacataire.minor_work_authorisation) && (
                            <>
                              <DocumentUpload
                                name="minor_work_authorisation"
                                label="Autorisation de travail pour mineur"
                                id={selectedVacataire._id}
                              />
                              <DocumentUpload
                                name="minor_identity_card"
                                label="Recto de la carte d'identité du responsable"
                                id={selectedVacataire._id}
                              />
                              <DocumentUpload
                                name="minor_identity_card_verso"
                                label="Verso de la carte d'identité du responsable"
                                id={selectedVacataire._id}
                              />
                            </>
                          )}
                          {(securityChecked ||
                            siapChecked ||
                            siap2Checked ||
                            siap3Checked) && (
                            <Grid>
                              <DocumentUpload
                                name="security_degree"
                                label={
                                  securityChecked
                                    ? "Copie de l'agrément"
                                    : "Copie du diplôme officiel"
                                }
                                id={selectedVacataire._id}
                              />
                              {userRole === Roles.Admin && (
                                <DocumentUpload
                                  name="security_card"
                                  label={
                                    securityChecked
                                      ? "Copie de la carte professionnelle"
                                      : "Copie du recyclage / MAC"
                                  }
                                  id={selectedVacataire._id}
                                />
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    ) : (
                      <Text>
                        Veuillez créer le vacataire avant de pouvoir déposer des
                        documents.
                      </Text>
                    )}
                  </TabPanel>
                  <TabPanel>
                    <Text
                      fontSize="1.5xl"
                      fontFamily="montserrat"
                      fontWeight="bold"
                      display="flex" // Utilisez Flexbox pour aligner les éléments
                      alignItems="center" // Alignez verticalement les éléments au centre
                    >
                      Contrats & DPAE
                      <Tooltip
                        label={
                          <>
                            Format pour les contrats :<br />
                            CDD-ESTAC_NOMEVENEMENT-26-01-2024.pdf.
                            <br />
                            Pas de format spécifique pour les DPAE.
                          </>
                        }
                      >
                        <Box
                          as="span"
                          ml={2}
                          display="flex"
                          alignItems="center"
                        >
                          <FaQuestionCircle />
                        </Box>
                      </Tooltip>
                    </Text>
                    {/*
                    {selectedVacataire &&
                    selectedVacataire.contrats &&
                    selectedVacataire._id ? (
                      <ContractList
                        contrats={contrats}
                        setContrats={setContrats}
                        id={selectedVacataire._id}
                        autorisations={selectedVacataire.autorisations}
                      />
                    ) : null}

                    {selectedVacataire &&
                    selectedVacataire.contrats &&
                    selectedVacataire._id ? (
                      <DpaeList
                        dpae={dpae}
                        setDpae={setDpae}
                        id={selectedVacataire._id}
                      />
                    ) : null}
                    */}
                    {selectedVacataire && selectedVacataire._id ? (
                      <CombinedComponent
                        vacataireId={selectedVacataire._id}
                        contratsData={selectedVacataire.contrats}
                        dpaesData={selectedVacataire.dpae}
                      />
                    ) : null}

                    <Text
                      fontSize="1.5xl"
                      fontFamily="montserrat"
                      fontWeight="bold"
                      marginTop={"4"}
                    >
                      Bulletin de paie
                    </Text>
                    {selectedVacataire &&
                    selectedVacataire._id &&
                    selectedVacataire.pay_slip ? (
                      <PaySlipComponent
                        vacataireId={selectedVacataire._id}
                        paySlipData={selectedVacataire.pay_slip}
                      />
                    ) : null}
                  </TabPanel>
                  <TabPanel>
                    <Text
                      fontSize="1.5xl"
                      fontFamily="montserrat"
                      fontWeight="bold"
                    >
                      Informations complémentaires
                    </Text>
                    <InformationsForm />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalBody>
            <ModalFooter>
              <Button
                mr={3}
                onClick={onClose}
                variant="outline"
                borderColor="red"
              >
                Annuler
              </Button>
              {tabIndex === 4 ? (
                <Button type="submit" variant="primaryButton">
                  Enregistrer
                </Button>
              ) : (
                <Button onClick={handleNext} variant="primaryButton">
                  Suivant
                </Button>
              )}
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export default CreateEditVacataire;
