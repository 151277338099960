import {
  BellIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  Icon,
  SearchIcon,
  TimeIcon,
  WarningIcon,
} from "@chakra-ui/icons";

import {
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ChildCare } from "@mui/icons-material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FaBell, FaFileSignature } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { getOneEvent } from "../../../api/ApiEvenement";
import { getVacataire } from "../../../api/ApiVacataire";
import {
  addNewZone,
  deleteZone,
  getZones,
} from "../../../api/zones/PrincipalZones";
import {
  addNewZoneSecondary,
  deleteZoneSecondary,
  getZonesSecondary,
} from "../../../api/zones/SecondaryZones";
import {
  addVacataireToZone,
  ConvoqType,
  createZoneInEvent,
  deleteVacataireFromZone,
  deleteZoneForEvent,
  fetchZonesForEventAndService,
  sendConvocationEmail,
  sendRelanceEmail,
  sendTestConvocMail,
  updateVacataireDetailsInZone,
  updateVacataireStatus,
} from "../../../api/zones/VacatairesToZone";
import { AuthContext } from "../../../utils/context";
import debounce, { timestampToTime } from "../../../utils/debounce";
import { Vacataire } from "../../../utils/types";
import {
  categoryToRoles,
  categoryToRolesHorsMatch,
  PersonnelCategory,
  PersonnelCategoryLabels,
  PersonnelRoles,
  PersonnelRolesHorsMatch,
  PersonnelRolesLabels,
  PersonnelRolesLabelsHorsMatch,
} from "../../../utils/types/EnumPost";
import { GetInput } from "../../../utils/types/EventType";
import { Roles } from "../../../utils/types/roles.interface";
import { LoaderSpinner } from "../../_shared/loader/CustomSpinner";
import { EmptyState } from "../../_shared/state/EmptyState";
import { DpaeModal } from "../modal/DpaeModal";
import DeleteVacataireFromZone from "./DeleteVacatairesZone";
import { DressCodeSelector } from "./DressCodeSelector";
import { ZoneHeader } from "./ZoneHeader";
import { ZoneSelector } from "./ZoneSelector";
import { ZoneSelectorSecondary } from "./ZoneSelectorSecondary";
const imagePath = "/images/empty_state.png";
type EvenementVacataire = {
  status: string;
  name: string;
  secondaryZone: string;
  function: string;
  startTime: string;
  isEditing?: boolean;
};
type VacataireDetails = {
  vacataireId: string;
  startHour?: number;
  category?: PersonnelCategory;
  fonction?: PersonnelRoles;
  status_dpae?: string;
  status_contrat?: string;
  status_control?: string;
  isMinor?: boolean;
  zoneSecondary?: string;
  firstname?: string;
  mail?: string;
  name?: string;
  serial_number?: number;

  dressCode?: string;
  status?: string;
};

type ZoneVacataire = {
  _id: string;
  name: string;
  eventId: string;
  service: string;
  vacataires: VacataireDetails[];
};
type Zone = {
  name: string;
  _id: string;
  vacataires: EvenementVacataire[];
};
type SelectedVacataire = {
  zoneName: string;
  vacataireId: string;
  eventId: string;
  serviceName: string;
} | null;

export interface VacataireStatus {
  status:
    | typeof STATUS_NON_CONVOQUE
    | typeof STATUS_EN_ATTENTE
    | typeof STATUS_ACCEPT
    | typeof STATUS_REFUSE;
}
export const STATUS_NON_CONVOQUE = "Non convoqué(e)";
export const STATUS_EN_ATTENTE = "Convoqué(e)";
export const STATUS_ACCEPT = "Présent(e)";
export const STATUS_REFUSE = "Absent(e)";
export const STATUS_CANCEL = "Annulation salarié";

export const STATUS_CONTRAT_ATTENTE = "En attente de signature";
export const STATUS_CONTRAT_OK = "Contrat signé";
export const STATUS_CONTRAT_CANCEL = "Contrat annulé";

export const STATUS_DPAE_OK = "DPAE validée";
export const STATUS_DPAE_REFUSE = "Erreur DPAE";
export const STATUS_DPAE_CANCEL = "Dpae non générée";

export const STATUS_CONTROL_READY_SIGN = "Aucune signature en cours";
export const STATUS_CONTROL_SIGN = "Signature déjà en cours";

const STATUS_CONTRAT = [
  STATUS_CONTRAT_OK,
  STATUS_CONTRAT_ATTENTE,
  STATUS_CONTRAT_CANCEL,
];

const STATUS_DPAE = [STATUS_DPAE_OK, STATUS_DPAE_REFUSE, STATUS_DPAE_CANCEL];

const STATUS = [
  STATUS_NON_CONVOQUE,
  STATUS_EN_ATTENTE,
  STATUS_ACCEPT,
  STATUS_REFUSE,
  STATUS_CANCEL,
];

const STATUS_COLORS = {
  [STATUS_NON_CONVOQUE]: "gray.500",
  [STATUS_EN_ATTENTE]: "yellow.400",
  [STATUS_ACCEPT]: "green.500",
  [STATUS_REFUSE]: "red.500",
  [STATUS_CANCEL]: "orange.600",
};

const STATUS_COLORS_CONTRAT = {
  [STATUS_CONTRAT_ATTENTE]: "blue.600",
  [STATUS_CONTRAT_OK]: "green.500",
  [STATUS_CONTRAT_CANCEL]: "orange.600",
};

const STATUS_ICONS = {
  [STATUS_NON_CONVOQUE]: <BellIcon />,
  [STATUS_EN_ATTENTE]: <TimeIcon />,
  [STATUS_ACCEPT]: <CheckIcon />,
  [STATUS_REFUSE]: <CloseIcon />,
  [STATUS_CANCEL]: <WarningIcon />,
};

const STATUS_COLORS_DPAE = {
  [STATUS_DPAE_OK]: "green.500",
  [STATUS_DPAE_REFUSE]: "red.500",
  [STATUS_DPAE_CANCEL]: "orange.600",
};

const STATUS_CONTRAT_ICONS = {
  [STATUS_CONTRAT_ATTENTE]: <EditIcon />,
  [STATUS_CONTRAT_OK]: <CheckIcon />,
  [STATUS_CONTRAT_CANCEL]: <WarningIcon />,
};

const STATUS_DPAE_ICONS = {
  [STATUS_DPAE_REFUSE]: <CloseIcon />,
  [STATUS_DPAE_OK]: <CheckIcon />,
  [STATUS_DPAE_CANCEL]: <WarningIcon />,
};

const EvenementZones = () => {
  const {
    isOpen: isDpaeOpen,
    onOpen: onOpenDpaeModal,
    onClose: onCloseDpaeModal,
  } = useDisclosure();
  const [eventToDpae, setEventToDpae] = useState<string | null>(null);
  const [vacataireId, setVacataireId] = useState<string | null>(null);

  const [showAddLineIndex, setShowAddLineIndex] = useState<number | null>(null);
  const [isRelancerActive, setIsRelancerActive] = useState(false);
  const { id, service } = useParams<{ id?: string; service?: string }>();
  const [selectedZones, setSelectedZones] = useState<string[]>([]);
  const [selectedVacataire, setSelectedVacataire] =
    useState<SelectedVacataire>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { userRole } = useContext(AuthContext);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [filterPresence, setFilterPresence] = useState<string>("");
  const [filterContrat, setFilterContrat] = useState<string>("");
  const [filterDpae, setFilterDpae] = useState<string>("");

  type CombinedRoles = PersonnelRoles | PersonnelRolesHorsMatch;

  const [dressCodeInputs, setDressCodeInputs] = useState<{
    [key: string]: string;
  }>({});
  const [localTime, setLocalTime] = useState<string>("");

  const [tmpZone, setTmpZone] = useState("");

  const [currentRoles, setCurrentRoles] = useState<PersonnelRoles[]>([]);

  const [selectedCategory, setSelectedCategory] =
    useState<PersonnelCategory | null>(null);
  const [isTyping, setIsTyping] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [selectedFunction, setSelectedFunction] =
    useState<PersonnelRoles | null>(null);

  if (!id) {
    throw new Error("ID not found in URL");
  }

  const fetchEventDetails = async (eventId: string) => {
    return getOneEvent(eventId);
  };

  /** MODAL DPAE */

  const openDpaeModal = (eventId: string, vacataireId?: string) => {
    setEventToDpae(eventId);
    setVacataireId(vacataireId || null);
    onOpenDpaeModal();
  };
  /** Chargement des vacataires */
  const {
    data: allVacataires,
    isError: isErrorVacataire,
    isLoading: isLoadingVacataire,
  } = useQuery("vacataires", getVacataire);

  const options = allVacataires?.map((vacataire: Vacataire) => ({
    value: vacataire._id,
    label: `${vacataire.firstname} ${vacataire.name}`,
  }));

  /** Chargement des zones */

  const {
    data: eventDetails,
    isError,
    isLoading,
  } = useQuery(["eventDetails", id], () => fetchEventDetails(id));

  const hoverBgColor = useColorModeValue("gray.100", "gray.700");
  // Fonction pour convoquer le vacataire
  const handleAddVacataire = (zoneName: string, vacataireId: string) => {
    let updateData = {};

    if (service === "Sécurité") {
      updateData = {
        category: PersonnelCategory.SECURITE,
        dressCode: "SSIAP ET SECURITE",
      };
    }

    //TODO IF SERVICE MINEUR ALORS

    const dataToSend = {
      eventId: eventDetails._id,
      serviceName: service ? service : "",
      zoneId: zoneName,
      vacataireId: vacataireId,
      ...updateData,
    };
    console.log("datatosend");
    console.log(dataToSend);

    mutation.mutate(dataToSend);
  };

  //Sticky column like excel
  const firstStickyColumnStyle = {
    position: "sticky",
    left: 0,
    bg: "white",
    margin: 0,
    zIndex: 1,
  };

  const secondStickyColumnStyle = {
    position: "sticky",
    left: "248px",
    margin: 0,
    bg: "white",
    zIndex: 1,
  };

  // Créez une référence pour stocker la fonction debouncée
  const debouncedUpdateRef = useRef(
    debounce(
      (
        eventId: string,
        serviceName: string,
        zoneName: string,
        vacataireId: string,
        timestamp: number
      ) => {
        const updateDto = {
          startHour: timestamp,
        };

        updateVacataireMutation.mutate({
          eventId: eventId,
          serviceName: serviceName,
          zoneName: zoneName,
          vacataireId: vacataireId,
          updateVacataireDto: updateDto,
        });
      },
      3000
    )
  );

  const handleStartHourChange =
    (zoneName: string, vacataireId: string) => (event: any) => {
      setLocalTime(event.target.value);

      const timeParts = event.target.value.split(":");
      const hours = parseInt(timeParts[0], 10);
      const minutes = parseInt(timeParts[1], 10);

      const now = new Date();
      const localDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        hours,
        minutes
      );

      const timestamp = localDate.getTime() / 1000;

      // Utilisez la référence pour appeler la fonction debouncée
      debouncedUpdateRef.current(
        eventDetails._id,
        service ? service : "",
        zoneName,
        vacataireId,
        timestamp
      );
    };

  const updateVacataireMutation = useMutation(updateVacataireDetailsInZone, {
    onSuccess: () => {
      toast({
        title: "Le vacataire a été mis à jour.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Re-fetch les données pour mettre à jour le tableau
      queryClient.invalidateQueries(["zonesForEventService", id]);
    },
    onError: (error) => {
      toast({
        title: "Erreur",
        description: `Erreur lors de la mise à jour du vacataire.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleVacataireUpdate = (
    zoneName: string,
    vacataireId: string,
    details: any
  ) => {
    console.log(details);
    const dataToUpdate = {
      eventId: eventDetails._id,
      serviceName: service ? service : "",
      zoneName: zoneName,
      vacataireId: vacataireId,
      updateVacataireDto: details,
    };
    updateVacataireMutation.mutate(dataToUpdate);
  };

  /*
  const handleConvocation = (zoneName: string, vacataireId: string) => {
    const details = {
      status: STATUS_EN_ATTENTE,
    };

    handleVacataireUpdate(zoneName, vacataireId, details);
  };
  */

  const mutation = useMutation(addVacataireToZone, {
    onSuccess: () => {
      toast({
        title: "Succès",
        description: `Le vacataire a été ajouté au service ${service} et à la zone ${tmpZone}.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Re-fetch les données pour mettre à jour le tableau
      queryClient.invalidateQueries(["zonesForEventService", id]);
    },
    onError: (error) => {
      console.error("Erreur lors de l'ajout du vacataire:", error);
      toast({
        title: "Erreur",
        description: `Erreur lors de l'ajout du vacataire au service ${service}.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
  const [rolesState, setRolesState] = useState<
    Record<string, CombinedRoles | null>
  >({});

  const {
    data: zonesForEventService,
    isLoading: zonesLoading,
    isError: zonesError,
  } = useQuery(["zonesForEventService", id, service], () =>
    fetchZonesForEventAndService(id, service!)
  );

  useEffect(() => {
    if (zonesForEventService) {
      const zoneIds = zonesForEventService.map((zone: Zone) => zone._id);
      setSelectedZones(zoneIds);
    }
  }, [zonesForEventService]);

  const updateStatusMutation = useMutation(
    ({
      zoneId,
      vacataireId,
      status,
    }: {
      zoneId: string;
      vacataireId: string;
      status: string;
    }) => updateVacataireStatus({ zoneId, vacataireId, status }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("zones");
      },
    }
  );

  const handleConvoquer = (zoneId: string, vacataireId: string) => {
    updateStatusMutation.mutate({ zoneId, vacataireId, status: STATUS[1] });
  };

  // Fonction pour supprimer le vacataire
  const deleteVacataireMutation = useMutation(
    ({
      zoneName,
      vacataireId,
      eventId,
      serviceName,
    }: {
      zoneName: string;
      vacataireId: string;
      eventId: string;
      serviceName: string;
    }) =>
      deleteVacataireFromZone({ zoneName, vacataireId, eventId, serviceName }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("zonesForEventService");
        toast({
          title: `Vacataire supprimé `,
          description: `La vacataire a été supprimé du service ${service} et de la zone ${tmpZone}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsDeleteModalOpen(false);
      },
      onError: () => {
        toast({
          title: `Erreur lors de la suppression du vacataire `,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsDeleteModalOpen(false);
      },
    }
  );

  const handleSupprimer = (
    zoneName: string,
    vacataireId: string,
    eventId: string,
    serviceName: string
  ) => {
    setTmpZone(zoneName);

    setSelectedVacataire({ zoneName, vacataireId, eventId, serviceName });
    setIsDeleteModalOpen(true);
  };

  const deleteZoneForEventMutation = useMutation(
    ({
      eventId,
      zoneName,
      serviceName,
    }: {
      eventId: string;
      zoneName: string;
      serviceName: string;
    }) => deleteZoneForEvent({ eventId, zoneName, serviceName }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["zonesForEventService", id, service]);
      },
    }
  );

  const handleDeleteZoneForEvent = (
    eventId: string,
    zoneName: string,
    serviceName: string
  ) => {
    deleteZoneForEventMutation.mutate({ eventId, zoneName, serviceName });
  };

  /** Zones principal */
  const queryClient = useQueryClient();

  const [zoneInput, setZoneInput] = useState("");
  const [isZoneOpen, setIsZoneOpen] = useState(false);
  const toast = useToast();
  const {
    data: zones,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useQuery<GetInput[], Error>("zones", getZones);

  const handleZoneInputChange = (e: any) => setZoneInput(e.target.value);

  const addZoneMutation = useMutation(addNewZone, {
    onSuccess: () => {
      queryClient.invalidateQueries("zones");
    },
  });

  const deleteZoneMutation = useMutation(deleteZone, {
    onSettled: () => {
      queryClient.invalidateQueries("zones");
    },
  });

  const handleAddZone = () => {
    if (
      zoneInput &&
      !zones?.some((zone: GetInput) => zone.name === zoneInput)
    ) {
      addZoneMutation.mutate(zoneInput);
      toast({
        title: `Zone ajoutée`,
        description: `La zone ${zoneInput} a été ajoutée`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setZoneInput("");
    }
  };

  const handleSelectZone = (zone: GetInput) => {
    // Ajoute la zone à l'état si elle n'est pas déjà présente
    if (!selectedZones.includes(zone._id)) {
      setSelectedZones((prevZones) => [...prevZones, zone._id]);

      // Ajouter la zone à l'événement et au service
      addZoneToEventAndServiceMutation.mutate({
        name: zone.name,
        eventId: id!,
        service: service!,
      });
    }
    setZoneInput(zone.name);
    setIsZoneOpen(false);
  };

  const handleDeleteZone = (zone: GetInput) => {
    deleteZoneMutation.mutate(zone._id);
    toast({
      title: `Zone supprimée`,
      description: `La zone ${zone.name} a été supprimée`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setZoneInput("");
  };

  const debouncedUpdate = useCallback(
    debounce(
      (
        eventId: string,
        serviceName: string,
        zoneName: string,
        vacataireId: string,
        timestamp: number
      ) => {
        const updateDto = {
          // Ajoutez d'autres champs si nécessaire
          startHour: timestamp,
        };

        updateVacataireMutation.mutate({
          eventId: eventId,
          serviceName: serviceName,
          zoneName: zoneName,
          vacataireId: vacataireId,
          updateVacataireDto: updateDto,
        });
      },
      3000
    ),
    [updateVacataireMutation]
  ); // dépendances
  const addZoneToEventAndServiceMutation = useMutation(
    (data: { name: string; eventId: string; service: string }) => {
      return createZoneInEvent(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["zonesForEventService", id, service]);
        toast({
          title: "Succès",
          description: "Zone ajoutée à l'événement et au service avec succès.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: "Erreur",
          description:
            "Quelque chose s'est mal passé. La zone est surement déjà existante",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const zonesState = {
    inputValue: zoneInput,
    isOpen: isZoneOpen,
    setIsOpen: setIsZoneOpen,
    handleInputChange: handleZoneInputChange,
    handleAdd: handleAddZone,
    handleSelect: handleSelectZone,
    handleDelete: handleDeleteZone,
  };

  /** Zone secondaire */
  const [openVacataireIndex, setOpenVacataireIndex] = useState<number | null>(
    null
  );

  const [zoneSecondaryInputs, setZoneSecondaryInputs] = useState<{
    [key: string]: string;
  }>({});
  const [isZoneSecondaryOpen, setIsZoneSecondaryOpen] = useState(false);
  const [openVacataireId, setOpenVacataireId] = useState<string | null>(null);

  const {
    data: zonesSecondary,
    isLoading: zoneSecondaryLoading,
    error: zoneSecondaryError,
  } = useQuery<GetInput[], Error>("zonesSecondary", getZonesSecondary);

  const handleZoneSecondaryInputChange = (e: any) =>
    setZoneSecondaryInputs(e.target.value);

  const addZoneSecondaryMutation = useMutation(addNewZoneSecondary, {
    onSuccess: () => {
      queryClient.invalidateQueries("zonesSecondary");
    },
  });

  const deleteZoneSecondaryMutation = useMutation(deleteZoneSecondary, {
    onSettled: () => {
      queryClient.invalidateQueries("zonesSecondary");
    },
  });

  const handleAddZoneSecondary = (key: string) => {
    const zoneInput = zoneSecondaryInputs[key];
    if (
      zoneInput &&
      !zonesSecondary?.some(
        (zoneSecondary: GetInput) => zoneSecondary.name === zoneInput
      )
    ) {
      addZoneSecondaryMutation.mutate(zoneInput);
      toast({
        title: `Zone secondaire ajoutée`,
        description: `La zone secondaire ${zoneInput} a été ajoutée`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setZoneSecondaryInputs((prev) => ({
        ...prev,
        [key]: "",
      }));
    }
  };

  const handleSelectDressCode = (
    zoneName: string,
    vacataireId: string,
    dressCode: string
  ) => {
    const updateData = {
      dressCode: dressCode,
    };
    handleVacataireUpdate(zoneName, vacataireId, updateData);
  };
  const handleSelectZoneSecondary = (
    zoneName: string,
    vacataireId: string,
    zonesSecondary: GetInput
  ) => {
    const updateData = {
      zoneSecondary: zonesSecondary.name,
    };
    handleVacataireUpdate(zoneName, vacataireId, updateData);

    setZoneSecondaryInputs((prev) => ({
      ...prev,
      [vacataireId]: zonesSecondary.name,
    }));
    setIsZoneSecondaryOpen(false);
  };

  const handleDeleteZoneSecondary = (zonesSecondary: GetInput) => {
    deleteZoneSecondaryMutation.mutate(zonesSecondary._id);
    toast({
      title: `Zone secondaire supprimée`,
      description: `La zone secondaire ${zonesSecondary.name} a été supprimée`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setZoneInput("");
  };

  const addZoneSecondaryToEventAndServiceMutation = useMutation(
    (data: { name: string; eventId: string; service: string }) => {
      return updateVacataireDetailsInZone(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["zonesForEventService", id, service]);
        toast({
          title: "Succès",
          description: "Zone ajoutée à l'événement et au service avec succès.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (error) => {
        toast({
          title: "Erreur",
          description: "Quelque chose s'est mal passé.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const zoneSecondaryState = {
    inputValue: zoneSecondaryInputs,
    isOpen: openVacataireId,
    setIsOpen: setOpenVacataireId,

    handleInputChange: handleZoneSecondaryInputChange,
    handleAdd: handleAddZoneSecondary,
    handleSelect: handleSelectZoneSecondary,
    handleDelete: handleDeleteZoneSecondary,
  };

  const handleConvocation = useMutation(
    (convocationData: ConvoqType) => sendConvocationEmail(convocationData),
    {
      onMutate: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries(["zonesForEventService", id, service]);

        // Affiche un toast de succès
        toast({
          title: "Convocation envoyée avec succès",
          status: "success",
          duration: 3000, // Durée pendant laquelle le toast sera affiché (en ms)
          isClosable: true, // Permet à l'utilisateur de fermer le toast
        });
      },
      onError: (error: any) => {
        let errorMessage = error.message || "Veuillez réessayer plus tard.";

        // Affiche un toast d'erreur
        toast({
          title: "Erreur lors de l'envoi de la convocation",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  const handleTestConvocation = useMutation(
    (convocationData: ConvoqType) => sendTestConvocMail(convocationData),
    {
      onMutate: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries(["zonesForEventService", id, service]);

        // Affiche un toast de succès
        toast({
          title: "La convocation est prête à être envoyée",
          status: "success",
          duration: 3000, // Durée pendant laquelle le toast sera affiché (en ms)
          isClosable: true, // Permet à l'utilisateur de fermer le toast
        });
      },
      onError: (error: any) => {
        let errorMessage = error.message || "Veuillez réessayer plus tard.";

        // Affiche un toast d'erreur
        toast({
          title: "Erreur lors de l'envoi de la convocation",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );
  const handlePresenceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterPresence(e.target.value);
    setFilterContrat("");
    setFilterDpae("");
  };

  const handleContratChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterContrat(e.target.value);
    setFilterPresence("");
    setFilterDpae("");
  };

  const handleDpaeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterDpae(e.target.value);
    setFilterPresence("");
    setFilterContrat("");
  };

  const handleRelance = useMutation(
    (convocationData: ConvoqType) => sendRelanceEmail(convocationData),
    {
      onMutate: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries(["zonesForEventService", id, service]);

        // Affiche un toast de succès
        toast({
          title: "Convocation envoyée avec succès",
          status: "success",
          duration: 3000, // Durée pendant laquelle le toast sera affiché (en ms)
          isClosable: true, // Permet à l'utilisateur de fermer le toast
        });
      },
      onError: (error) => {
        console.error(
          "Une erreur s'est produite lors de l'envoi de la convocation :",
          error
        );

        // Affiche un toast d'erreur
        toast({
          title: "Erreur lors de l'envoi de la convocation",
          description: "Veuillez réessayer plus tard.",
          status: "error",
          duration: 5000, // Durée pendant laquelle le toast sera affiché (en ms)
          isClosable: true, // Permet à l'utilisateur de fermer le toast
        });
      },
    }
  );

  if (zonesLoading) {
    return (
      <div>
        <LoaderSpinner />
      </div>
    );
  }

  // Si une erreur se produit lors de la récupération des données
  if (zonesError) {
    return <div>Erreur lors du chargement des zones</div>;
  }

  return (
    <>
      {eventDetails &&
      (eventDetails.category?.name === "MATCHS" ||
        eventDetails.category?.name === "Hors match") ? (
        <div>
          <Box
            textAlign="left"
            ml={{ base: "2", md: "2" }}
            mt={{ base: "2", md: "2" }}
          >
            <Text color="muted" size="xs">
              {service}
            </Text>
          </Box>
          <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 2 }}>
            <Box mb="0">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={SearchIcon} color="gray.300" />} // Remplacez SearchIcon par l'icône que vous utilisez
                />
                <Input
                  placeholder="Recherche par nom, prénom, email ou matricule..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
            </Box>
            <Box>
              <Stack>
                <Box>
                  <Box py="12">
                    <Flex justify="space-between" align="center" py="1">
                      <Heading size={"sm"} color="primary">
                        Listing événement -{" "}
                        {eventDetails &&
                        eventDetails.category &&
                        eventDetails.category.name
                          ? eventDetails.category.name
                          : ""}
                      </Heading>
                    </Flex>
                    <Text color="muted">
                      {isLoading && "Chargement de l'événement..."}
                      {isError && "Erreur lors du chargement de l'événement."}
                      {eventDetails &&
                        `${eventDetails.name_event} ${
                          eventDetails.home_team.type === "MATCHS"
                            ? `: ${eventDetails.home_team.name} - ${eventDetails.away_team.name}`
                            : ""
                        }`}
                    </Text>

                    {/* Empty State Check */}
                    {!zonesForEventService ||
                    zonesForEventService.length === 0 ? (
                      <>
                        <EmptyState
                          imagePath={imagePath}
                          title="Aucune zone trouvée!"
                          description="Il semble que vous n'avez pas encore créé de zones pour ce service. Commencez par sélectionner ou ajouter une zone pour gérer les vacataires."
                          textButton="Sélectionner une zone"
                        />
                        <ZoneSelector {...zonesState} zones={zones || []} />
                      </>
                    ) : (
                      <ZoneSelector {...zonesState} zones={zones || []} />
                    )}
                    <Box mb="4" mt="4" display="flex" justifyContent="left">
                      <Select
                        placeholder="Filtrer par Présence"
                        value={filterPresence}
                        onChange={handlePresenceChange}
                        mr="2"
                        width="200px"
                      >
                        <option value={STATUS_NON_CONVOQUE}>
                          {STATUS_NON_CONVOQUE}
                        </option>
                        <option value={STATUS_EN_ATTENTE}>
                          {STATUS_EN_ATTENTE}
                        </option>
                        <option value={STATUS_ACCEPT}>{STATUS_ACCEPT}</option>
                        <option value={STATUS_REFUSE}>{STATUS_REFUSE}</option>
                        <option value={STATUS_CANCEL}>{STATUS_CANCEL}</option>
                      </Select>
                      <Select
                        placeholder="Filtrer par Contrat"
                        value={filterContrat}
                        onChange={handleContratChange}
                        mr="2"
                        width="200px"
                      >
                        <option value={STATUS_CONTRAT_CANCEL}>
                          {STATUS_CONTRAT_CANCEL}
                        </option>
                        <option value={STATUS_CONTRAT_OK}>
                          {STATUS_CONTRAT_OK}
                        </option>
                        <option value={STATUS_CONTRAT_ATTENTE}>
                          {STATUS_CONTRAT_ATTENTE}
                        </option>
                      </Select>
                      <Select
                        placeholder="Filtrer par DPAE"
                        value={filterDpae}
                        onChange={handleDpaeChange}
                        width="200px"
                      >
                        <option value={STATUS_DPAE_OK}>{STATUS_DPAE_OK}</option>
                        <option value={STATUS_DPAE_REFUSE}>
                          {STATUS_DPAE_REFUSE}
                        </option>
                        <option value={STATUS_DPAE_CANCEL}>
                          {STATUS_DPAE_CANCEL}
                        </option>
                      </Select>
                    </Box>
                    {zonesForEventService.map(
                      (zone: any, zoneIndex: number) => {
                        const filteredVacataires = zone.vacataires
                          .filter(
                            (vacataire: any) =>
                              vacataire.name
                                ?.toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              vacataire.firstname
                                ?.toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              vacataire.mail
                                ?.toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              vacataire.serial_number
                                ?.toString()
                                .includes(searchTerm)
                          )
                          .filter((vacataire: any) => {
                            return (
                              (filterPresence === "" ||
                                vacataire.status === filterPresence) &&
                              (filterContrat === "" ||
                                vacataire.status_contrat === filterContrat) &&
                              (filterDpae === "" ||
                                vacataire.status_dpae === filterDpae)
                            );
                          });

                        return (
                          <Box key={zone._id} my="8">
                            <ZoneHeader
                              eventDetails={eventDetails ? eventDetails : ""}
                              zone={zone}
                              service={service ? service : ""}
                              eventId={eventDetails ? eventDetails._id : ""}
                              onDeleteZone={handleDeleteZoneForEvent}
                            />

                            <Box overflowX="auto">
                              <Table variant="simple" minW="max-content">
                                <Thead>
                                  <Tr>
                                    <Th sx={firstStickyColumnStyle}>Status</Th>
                                    <Th sx={secondStickyColumnStyle}>Nom</Th>
                                    <Th>Heure de début de poste</Th>
                                    <Th>Zone secondaire</Th>
                                    <Th>Catégorie</Th>
                                    <Th>Fonction</Th>
                                    <Th>Dress Code</Th>
                                    <Th>Action</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {filteredVacataires.map(
                                    (
                                      vacataire: VacataireDetails,
                                      vacataireIndex: number
                                    ) => {
                                      let rolesForThisCategory:
                                        | (
                                            | PersonnelRoles
                                            | PersonnelRolesHorsMatch
                                          )[]
                                        | undefined;

                                      if (vacataire.category) {
                                        if (
                                          eventDetails.category?.name ===
                                            "MATCHS" &&
                                          vacataire.category in categoryToRoles
                                        ) {
                                          rolesForThisCategory =
                                            categoryToRoles[vacataire.category];
                                        } else if (
                                          vacataire.category in
                                          categoryToRolesHorsMatch
                                        ) {
                                          rolesForThisCategory =
                                            categoryToRolesHorsMatch[
                                              vacataire.category
                                            ];
                                        }
                                      }

                                      // Utilisez l'état ici
                                      const selectedRole =
                                        rolesState[vacataire.vacataireId] ||
                                        (rolesForThisCategory
                                          ? rolesForThisCategory[0]
                                          : null);

                                      return (
                                        <Tr key={vacataire.vacataireId}>
                                          <Td sx={firstStickyColumnStyle}>
                                            <Box mb="2">
                                              <Text fontSize="xs" mb="1">
                                                Statut
                                              </Text>
                                              <Select
                                                fontSize="sm"
                                                isDisabled={
                                                  userRole == Roles.LimitedAdmin
                                                }
                                                h="25px"
                                                value={vacataire.status}
                                                onChange={(e) => {
                                                  handleVacataireUpdate(
                                                    zone.name,
                                                    vacataire.vacataireId,
                                                    {
                                                      status: e.target.value,
                                                      status_control:
                                                        STATUS_CONTROL_READY_SIGN,
                                                    }
                                                  );
                                                }}
                                                icon={
                                                  <Flex
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    color={
                                                      STATUS_COLORS[
                                                        vacataire.status as keyof typeof STATUS_COLORS
                                                      ]
                                                    }
                                                  >
                                                    {STATUS_ICONS[
                                                      vacataire.status as keyof typeof STATUS_ICONS
                                                    ]
                                                      ? React.cloneElement(
                                                          STATUS_ICONS[
                                                            vacataire.status as keyof typeof STATUS_ICONS
                                                          ],
                                                          { fontSize: "17px" }
                                                        )
                                                      : null}
                                                  </Flex>
                                                }
                                                color={
                                                  STATUS_COLORS[
                                                    vacataire.status as keyof typeof STATUS_COLORS
                                                  ]
                                                }
                                              >
                                                {STATUS.map((status) => (
                                                  <option
                                                    key={status}
                                                    value={status}
                                                  >
                                                    {status}
                                                  </option>
                                                ))}
                                              </Select>
                                            </Box>
                                            <Box mb="2">
                                              <Text fontSize="xs" mb="1">
                                                Contrat
                                              </Text>
                                              <Select
                                                h="25px"
                                                isDisabled={
                                                  userRole == Roles.LimitedAdmin
                                                }
                                                fontSize="sm"
                                                value={vacataire.status_contrat}
                                                onChange={(e) => {
                                                  handleVacataireUpdate(
                                                    zone.name,
                                                    vacataire.vacataireId,
                                                    {
                                                      status_contrat:
                                                        e.target.value,
                                                    }
                                                  );
                                                }}
                                                color={
                                                  STATUS_COLORS_CONTRAT[
                                                    vacataire.status_contrat as keyof typeof STATUS_COLORS_CONTRAT
                                                  ] || "gray.500" // Couleur par défaut
                                                }
                                                icon={
                                                  <Flex
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    color={
                                                      STATUS_COLORS_CONTRAT[
                                                        vacataire.status_contrat as keyof typeof STATUS_COLORS_CONTRAT
                                                      ]
                                                    }
                                                  >
                                                    {STATUS_CONTRAT_ICONS[
                                                      vacataire.status_contrat as keyof typeof STATUS_CONTRAT_ICONS
                                                    ]
                                                      ? React.cloneElement(
                                                          STATUS_CONTRAT_ICONS[
                                                            vacataire.status_contrat as keyof typeof STATUS_CONTRAT_ICONS
                                                          ],
                                                          { fontSize: "17px" }
                                                        )
                                                      : null}
                                                  </Flex>
                                                }
                                              >
                                                <option value="">
                                                  -- Sélectionner --
                                                </option>{" "}
                                                {/* Option vide */}
                                                {STATUS_CONTRAT.map(
                                                  (status_contrat) => (
                                                    <option
                                                      key={status_contrat}
                                                      value={status_contrat}
                                                    >
                                                      {status_contrat}
                                                    </option>
                                                  )
                                                )}
                                              </Select>
                                            </Box>
                                            <Box>
                                              <Text fontSize="xs" mb="1">
                                                DPAE
                                              </Text>
                                              <Select
                                                h="25px"
                                                fontSize="sm"
                                                isDisabled={
                                                  userRole == Roles.LimitedAdmin
                                                }
                                                value={vacataire.status_dpae}
                                                onChange={(e) => {
                                                  handleVacataireUpdate(
                                                    zone.name,
                                                    vacataire.vacataireId,
                                                    {
                                                      status_dpae:
                                                        e.target.value,
                                                    }
                                                  );
                                                }}
                                                color={
                                                  STATUS_COLORS_DPAE[
                                                    vacataire.status_dpae as keyof typeof STATUS_COLORS_DPAE
                                                  ] || "gray.500" // Couleur par défaut
                                                }
                                                icon={
                                                  <Flex
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    color={
                                                      STATUS_COLORS_DPAE[
                                                        vacataire.status_dpae as keyof typeof STATUS_DPAE_ICONS
                                                      ]
                                                    }
                                                  >
                                                    {STATUS_DPAE_ICONS[
                                                      vacataire.status_dpae as keyof typeof STATUS_DPAE_ICONS
                                                    ]
                                                      ? React.cloneElement(
                                                          STATUS_DPAE_ICONS[
                                                            vacataire.status_dpae as keyof typeof STATUS_DPAE_ICONS
                                                          ],
                                                          { fontSize: "17px" }
                                                        )
                                                      : null}
                                                  </Flex>
                                                }
                                              >
                                                <option value="">
                                                  -- Sélectionner --
                                                </option>{" "}
                                                {/* Option vide */}
                                                {STATUS_DPAE.map(
                                                  (status_dpae) => (
                                                    <option
                                                      key={status_dpae}
                                                      value={status_dpae}
                                                    >
                                                      {status_dpae}
                                                    </option>
                                                  )
                                                )}
                                              </Select>
                                            </Box>
                                          </Td>

                                          <Td sx={secondStickyColumnStyle}>
                                            <Box
                                              display="flex"
                                              alignItems="center"
                                            >
                                              {vacataire.isMinor && (
                                                <ChildCare
                                                  style={{ marginRight: "8px" }}
                                                />
                                              )}
                                              {vacataire.firstname}{" "}
                                              {vacataire.name}
                                            </Box>
                                          </Td>
                                          <Td>
                                            <Input
                                              minW="190px"
                                              defaultValue={timestampToTime(
                                                vacataire?.startHour
                                              )}
                                              placeholder="Heure de début"
                                              type="time"
                                              onChange={handleStartHourChange(
                                                zone.name,
                                                vacataire.vacataireId
                                              )}
                                            />
                                          </Td>
                                          <Td>
                                            <ZoneSelectorSecondary
                                              {...zoneSecondaryState}
                                              handleSelect={(zoneSecondary) => {
                                                handleSelectZoneSecondary(
                                                  zone.name,
                                                  vacataire.vacataireId,
                                                  zoneSecondary
                                                );
                                              }}
                                              handleAdd={() =>
                                                handleAddZoneSecondary(
                                                  vacataire.vacataireId
                                                )
                                              }
                                              inputValue={
                                                zoneSecondaryInputs[
                                                  vacataire.vacataireId
                                                ] ||
                                                vacataire.zoneSecondary ||
                                                ""
                                              }
                                              isOpen={
                                                openVacataireId ===
                                                vacataire.vacataireId
                                              }
                                              setIsOpen={() => {
                                                if (
                                                  openVacataireId ===
                                                  vacataire.vacataireId
                                                ) {
                                                  setOpenVacataireId(null);
                                                } else {
                                                  setOpenVacataireId(
                                                    vacataire.vacataireId
                                                  );
                                                }
                                              }}
                                              handleInputChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                              ) => {
                                                setZoneSecondaryInputs(
                                                  (prev) => ({
                                                    ...prev,
                                                    [vacataire.vacataireId]:
                                                      e.target.value,
                                                  })
                                                );
                                              }}
                                              zones={zonesSecondary || []}
                                            />
                                          </Td>
                                          <Td>
                                            <Select
                                              minW="190px"
                                              disabled={
                                                userRole == Roles.LimitedAdmin
                                                  ? true
                                                  : false
                                              }
                                              value={
                                                vacataire.category
                                                  ? vacataire.category
                                                  : service === "Sécurité"
                                                  ? PersonnelCategory.SECURITE
                                                  : vacataire.isMinor
                                                  ? PersonnelCategory.MINEUR
                                                  : ""
                                              }
                                              onChange={(e) => {
                                                const selectedCat = e.target
                                                  .value as PersonnelCategory;
                                                const updateData = {
                                                  category: selectedCat,
                                                  fonction: "",
                                                };
                                                handleVacataireUpdate(
                                                  zone.name,
                                                  vacataire.vacataireId,
                                                  updateData
                                                );
                                              }}
                                            >
                                              <option value="" disabled={true}>
                                                Sélectionner une catégorie
                                              </option>

                                              {Object.values(
                                                PersonnelCategory
                                              ).map((categoryValue) => (
                                                <option
                                                  key={categoryValue}
                                                  value={categoryValue}
                                                >
                                                  {
                                                    PersonnelCategoryLabels[
                                                      categoryValue
                                                    ]
                                                  }
                                                </option>
                                              ))}
                                            </Select>
                                          </Td>

                                          <Td>
                                            {vacataire.category ? (
                                              <Select
                                                minW="190px"
                                                disabled={
                                                  userRole == Roles.LimitedAdmin
                                                    ? true
                                                    : false
                                                }
                                                value={vacataire.fonction || ""}
                                                onChange={(e) => {
                                                  const isMatchCategory =
                                                    eventDetails.category
                                                      ?.name === "MATCHS";
                                                  const newRole =
                                                    isMatchCategory
                                                      ? (e.target
                                                          .value as unknown as PersonnelRoles)
                                                      : (e.target
                                                          .value as unknown as PersonnelRolesHorsMatch);

                                                  setRolesState((prev) => ({
                                                    ...prev,
                                                    [vacataire.vacataireId]:
                                                      newRole,
                                                  }));

                                                  const updateData = {
                                                    fonction: newRole,
                                                  };
                                                  handleVacataireUpdate(
                                                    zone.name,
                                                    vacataire.vacataireId,
                                                    updateData
                                                  );
                                                }}
                                              >
                                                <option
                                                  value=""
                                                  disabled={true}
                                                >
                                                  Sélectionner une fonction
                                                </option>

                                                {rolesForThisCategory?.map(
                                                  (role) => (
                                                    <option
                                                      key={role}
                                                      value={role}
                                                    >
                                                      {eventDetails.category
                                                        ?.name === "MATCHS"
                                                        ? PersonnelRolesLabels[
                                                            role as PersonnelRoles
                                                          ]
                                                        : PersonnelRolesLabelsHorsMatch[
                                                            role as PersonnelRolesHorsMatch
                                                          ]}
                                                    </option>
                                                  )
                                                )}
                                              </Select>
                                            ) : (
                                              <Text color="red.500">
                                                Aucune catégorie sélectionnée
                                              </Text>
                                            )}
                                          </Td>

                                          <Td>
                                            <DressCodeSelector
                                              isDisabled={
                                                userRole == Roles.LimitedAdmin
                                                  ? true
                                                  : false
                                              }
                                              dressCodeInput={
                                                dressCodeInputs[
                                                  vacataire.vacataireId
                                                ] ||
                                                vacataire.dressCode ||
                                                ""
                                              }
                                              onInputChange={(
                                                value: string
                                              ) => {
                                                setDressCodeInputs((prev) => ({
                                                  ...prev,
                                                  [vacataire.vacataireId]:
                                                    value,
                                                }));
                                              }}
                                              onSelect={(dressCode) => {
                                                handleSelectDressCode(
                                                  zone.name,
                                                  vacataire.vacataireId,
                                                  dressCode
                                                );
                                              }}
                                            />
                                          </Td>
                                          <Td>
                                            <HStack spacing="1">
                                              <Tooltip label="Convoquer le vacataire">
                                                <IconButton
                                                  color="primary"
                                                  icon={
                                                    <FaBell fontSize="1rem" />
                                                  }
                                                  variant="ghost"
                                                  aria-label="Convoquer"
                                                  isDisabled={
                                                    userRole ==
                                                    Roles.LimitedAdmin
                                                      ? true
                                                      : false ||
                                                        !vacataire.category ||
                                                        !vacataire.fonction ||
                                                        !vacataire.startHour ||
                                                        vacataire.status ===
                                                          STATUS_EN_ATTENTE ||
                                                        vacataire.status ===
                                                          STATUS_ACCEPT ||
                                                        vacataire.status ===
                                                          STATUS_REFUSE ||
                                                        vacataire.status ===
                                                          STATUS_CANCEL
                                                  }
                                                  onClick={() =>
                                                    handleConvocation.mutate({
                                                      eventId: eventDetails._id
                                                        ? eventDetails._id
                                                        : "",
                                                      eventName:
                                                        eventDetails.name_event
                                                          ? eventDetails.name_event
                                                          : "",
                                                      category:
                                                        vacataire.category
                                                          ? vacataire.category
                                                          : "",
                                                      serviceName: service
                                                        ? service
                                                        : "",
                                                      placeName:
                                                        eventDetails.place
                                                          ? eventDetails.place
                                                              .name
                                                          : "",
                                                      placeDescription:
                                                        eventDetails.place &&
                                                        eventDetails.place
                                                          .address
                                                          ? (eventDetails.place
                                                              .address.num ||
                                                              "") +
                                                            " " +
                                                            (eventDetails.place
                                                              .address.street ||
                                                              "") +
                                                            " " +
                                                            (eventDetails.place
                                                              .address
                                                              .zip_code || "") +
                                                            " " +
                                                            (eventDetails.place
                                                              .address.city ||
                                                              "")
                                                          : "",
                                                      vacataireId:
                                                        vacataire.vacataireId,
                                                      zoneName: zone.name,
                                                      zoneSecondaryName:
                                                        vacataire.zoneSecondary
                                                          ? vacataire.zoneSecondary
                                                          : "",
                                                      firstname:
                                                        vacataire.firstname
                                                          ? vacataire.firstname
                                                          : "",
                                                      type: eventDetails.category
                                                        ? eventDetails.category
                                                            .name
                                                        : "",
                                                      homeTeam:
                                                        eventDetails.home_team
                                                          ? eventDetails
                                                              .home_team.name
                                                          : "",
                                                      awayTeam:
                                                        eventDetails.away_team
                                                          ? eventDetails
                                                              .away_team.name
                                                          : "",
                                                      eventDate:
                                                        eventDetails.date_event
                                                          ? eventDetails.date_event
                                                          : 0,
                                                      convocTime:
                                                        vacataire.startHour
                                                          ? vacataire.startHour
                                                          : "",

                                                      fonction:
                                                        vacataire.fonction
                                                          ? vacataire.fonction
                                                          : "",
                                                      dressCode:
                                                        vacataire.dressCode
                                                          ? vacataire.dressCode
                                                          : "",
                                                      dressCodeDescription:
                                                        vacataire.dressCode
                                                          ? vacataire.dressCode
                                                          : "",
                                                    })
                                                  }
                                                />
                                              </Tooltip>

                                              <Tooltip label="Générer la dpae">
                                                <IconButton
                                                  color="secondary"
                                                  isDisabled={
                                                    userRole ==
                                                    Roles.LimitedAdmin
                                                  }
                                                  icon={
                                                    <FaFileSignature fontSize="1rem" />
                                                  }
                                                  variant="ghost"
                                                  aria-label="Générer la dpae"
                                                  onClick={() =>
                                                    openDpaeModal(
                                                      eventDetails._id,
                                                      vacataire.vacataireId
                                                    )
                                                  }
                                                />
                                              </Tooltip>
                                              <Tooltip label="Supprimer le vacataire">
                                                <IconButton
                                                  color="red"
                                                  icon={
                                                    <DeleteIcon fontSize="1rem" />
                                                  }
                                                  variant="ghost"
                                                  aria-label="Supprimer"
                                                  onClick={() =>
                                                    handleSupprimer(
                                                      zone.name,
                                                      vacataire.vacataireId,
                                                      eventDetails._id,
                                                      service ? service : ""
                                                    )
                                                  }
                                                />
                                              </Tooltip>
                                            </HStack>
                                          </Td>
                                        </Tr>
                                      );
                                    }
                                  )}

                                  <Tr>
                                    <Td colSpan={6}>
                                      <Flex align="center">
                                        {isLoadingVacataire ? (
                                          <LoaderSpinner />
                                        ) : isErrorVacataire ? (
                                          <Text>
                                            Erreur lors du chargement des
                                            vacataires
                                          </Text>
                                        ) : (
                                          <ReactSelect
                                            options={options}
                                            placeholder="Sélectionner un nouveau vacataire"
                                            onChange={(option: any) =>
                                              handleAddVacataire(
                                                zone.name,
                                                option?.value
                                              )
                                            }
                                            isSearchable
                                            menuPortalTarget={document.body}
                                            styles={{
                                              container: (provided) => ({
                                                ...provided,
                                                minWidth: "200px",
                                              }),
                                              menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 10000,
                                              }),
                                            }}
                                          />
                                        )}
                                        <Spacer />
                                      </Flex>
                                    </Td>
                                  </Tr>
                                </Tbody>
                              </Table>
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Container>

          {selectedVacataire && (
            <DeleteVacataireFromZone
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              vacataireDetails={selectedVacataire}
              onDeleteSuccess={() => {
                console.log("ok");
              }}
              deleteVacataireMutation={deleteVacataireMutation}
            />
          )}
        </div>
      ) : (
        <div>
          {" "}
          Ce type d'évènement n'est pas encore pris en charge par la plateforme
        </div>
      )}
      <DpaeModal
        isOpen={isDpaeOpen}
        onClose={onCloseDpaeModal}
        eventId={eventToDpae}
        vacataireId={vacataireId}
      />
    </>
  );
};

export default EvenementZones;
