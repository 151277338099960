import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import { AnomaliesTableProps } from "../../../utils/types/EventType";

export const AnomaliesTable: React.FC<AnomaliesTableProps> = ({
  anomalies,
}) => {
  if (!anomalies || anomalies.length === 0) return null;

  return (
    <Box overflowX="auto">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Nom de famille</Th>
            <Th>Prénom</Th>
            <Th>Code</Th>
            <Th>Message</Th>
          </Tr>
        </Thead>
        <Tbody>
          {anomalies.map((anomalie, index) => (
            <Tr key={index}>
              <Td>{anomalie.nom_famille}</Td>
              <Td>{anomalie.prenoms}</Td>
              <Td>{anomalie.code}</Td>
              <Td>{anomalie.message}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
