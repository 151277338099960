import {
  Box,
  Center,
  DarkMode,
  Flex,
  useColorModeValue as mode,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  HOME_PAGE,
  LOGIN_PAGE,
  PUBLIC_LOGIN_PAGE,
} from "../../utils/navigation/endpoints";
import { Roles } from "../../utils/types/roles.interface";
import { SideNavProtected } from "../_shared/navigation";
import { SideNavPublic } from "../_shared/navigation/SideNavPublic";
import { SideNavVacataires } from "../_shared/navigation/SideNavVacataires";
import { useAuth } from "./useAuth";

export const PublicRoutes = () => {
  const { isLoggedIn, userRole } = useAuth();
  const location = useLocation();

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { validationCode: validationCodeFromPath } = useParams();
  const validationCodeFromQuery = queryParams.get("validationCode");
  useEffect(() => {
    console.log(validationCodeFromPath);
    console.log(validationCodeFromQuery);
  }, [validationCodeFromPath, navigate]);
  useEffect(() => {
    if (isLoggedIn) {
      if (userRole === Roles.Admin || userRole === Roles.LimitedAdmin) {
        navigate(HOME_PAGE);
      }

      /*else {
        console.log("navig vers confirme");
        navigate(VACATAIRE_PROFIL_PAGE);
      }
      */
    }
  }, [isLoggedIn, navigate, userRole]);

  return (
    <Flex
      className="PublicLayout"
      minH={{ base: "auto", md: "100vh" }}
      bgGradient={{
        md: mode(
          "linear(to-r, primary 50%, white 50%)",
          "linear(to-r, primary 50%, gray.900 50%)"
        ),
      }}
    >
      <Flex maxW="8xl" mx="auto" width="full">
        <Box flex="1" display={{ base: "none", md: "block" }}>
          <DarkMode>
            {/* Content  */}
            <SideNavPublic />
          </DarkMode>
        </Box>
        <Center flex="1">
          <Outlet />
        </Center>
      </Flex>
    </Flex>
  );
};

export const ProtectedRoutes = () => {
  const { isLoggedIn, userRole } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !isLoggedIn ||
      (userRole !== Roles.Admin && userRole !== Roles.LimitedAdmin)
    ) {
      navigate(LOGIN_PAGE);
    }
  }, [isLoggedIn, navigate, userRole]);

  return (
    <Box position="relative">
      <Box display={{ base: "flex", md: "flex" }}>
        <Box bg="primary">
          <SideNavProtected />
        </Box>
        <Box flex="1" display="flex" flexDirection="column" my={4} mx={4}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export const VacatairesRoutes = () => {
  const { isLoggedIn, userRole } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const toast = useToast();
  const queryParams = new URLSearchParams(location.search);
  const response = queryParams.get("response");

  const { validationCode: validationCodeFromPath } = useParams();
  const validationCodeFromQuery = queryParams.get("validationCode");

  useEffect(() => {
    // Si validationCode est présent dans le segment de chemin
    if (validationCodeFromPath) {
      navigate(
        PUBLIC_LOGIN_PAGE.replace(":validationCode", validationCodeFromPath)
      );
    } else if (validationCodeFromQuery) {
    }
  }, [validationCodeFromPath, validationCodeFromQuery, navigate]);

  return (
    <Box position="relative">
      <Box display={{ base: "flex", md: "flex" }}>
        <Box bg="primary">
          <SideNavVacataires />
        </Box>
        <Box flex="1" my={4} mx={4}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
