import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Spinner,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import { HotKeys } from "react-hotkeys";
import {
  FaChevronDown,
  FaChevronUp,
  FaClipboardList,
  FaDownload,
  FaEdit,
  FaEllipsisV,
  FaEye,
  FaFileSignature,
  FaPlus,
  FaTrash,
} from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import {
  getAllPresenceAndDpaeStats,
  getPresenceAndDpaeStatsByService,
} from "../../api/ApiEvenement";
import { AuthContext } from "../../utils/context";
import { EVENTS_PAGE } from "../../utils/navigation/endpoints";
import { EvenementType } from "../../utils/types/EventType";
import { Roles } from "../../utils/types/roles.interface";
import EventDetailsDrawerBody from "./utils/EventDetailsDrawerBody";
import { generateEventXLSX, generateServiceXLSX } from "./zones/ExportExcel";

interface EventTableProps extends TableProps {
  data: EvenementType[];
  pageCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  openEditEvent: (event: EvenementType) => void;
  openServiceModal: (event: EvenementType) => void;
  openDpaeModal: (eventId: string) => void;
  deleteCoop: (eventId: string, serviceId: string) => void;
  openDeleteModal: (eventId: string) => void;
}

export const EventTable: React.FC<EventTableProps> = ({
  data,
  pageCount,
  onPageChange,
  openEditEvent,
  openServiceModal,
  openDpaeModal,
  deleteCoop,
  openDeleteModal,
  ...props
}) => {
  const keyMap = {
    EDIT_EVENT: ["command+e", "ctrl+e"],
    DOWNLOAD_TABLE: ["command+d", "ctrl+d"],
    GENERATE_DPAE: ["command+g", "ctrl+g"],
  };
  const createHandlers = (event: any) => ({
    EDIT_EVENT: () => openEditEvent(event),
    DOWNLOAD_TABLE: () => console.log("Download table"),
    GENERATE_DPAE: () => console.log("Generate DPAE"),
  });

  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
  const navigate = useNavigate();
  const { userRole } = useContext(AuthContext);

  const [isLowerThan1280] = useMediaQuery("(max-width: 1280px)");
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [presenceDpaeStats, setPresenceDpaeStats] = useState<{
    [key: string]: {
      totalPresent: number;
      totalDpaeValid: number;
      totalVacataires: number;
      totalContractValid: number;
    };
  }>({});
  const [serviceStats, setServiceStats] = useState<{
    [key: string]: {
      totalPresent: number;
      totalDpaeValid: number;
      totalVacataires: number;
      totalContractValid: number;
    };
  }>({});
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const stats = await getAllPresenceAndDpaeStats();
        setPresenceDpaeStats(stats);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchStats();
  }, [toast]);

  const fetchServiceStats = async (eventId: string, serviceName: string) => {
    try {
      const stats = await getPresenceAndDpaeStatsByService(
        eventId,
        serviceName
      );
      setServiceStats((prevStats) => ({
        ...prevStats,
        [`${eventId}_${serviceName}`]: stats,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const navigateZone = (eventId: string, serviceName: string) => {
    navigate(`${EVENTS_PAGE}/${eventId}/${serviceName}`);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);
  const [selectedEvent, setSelectedEvent] = useState<EvenementType | null>(
    null
  );
  const defaultColumnVisibility = {
    name: true,
    address: true,
    team: true,
    eventDate: true,
    actions: true,
  };

  const columnVisibility =
    useBreakpointValue({
      base: {
        name: true,
        team: true,
        address: false,
        eventDate: false,
        actions: true,
      },
      sm: {
        name: true,
        team: true,
        address: false,
        eventDate: false,
        actions: true,
      },
      md: {
        name: true,
        team: true,
        address: true,
        eventDate: true,
        actions: true,
      },
      lg: {
        name: true,
        team: true,
        address: true,
        eventDate: true,
        actions: true,
      },
    }) || defaultColumnVisibility;

  return (
    <Box maxWidth="100vw" overflowX="auto" marginX="auto">
      <Box width="100%" overflowX="scroll">
        <Table {...props} size="sm" marginX="auto" minWidth="1200px">
          <Thead>
            <Tr>
              {columnVisibility.name && <Th py={3}>Nom</Th>}
              <Th py={3}>Présence</Th>
              <Th py={3}>Contrats</Th>
              <Th py={3}>Dpae</Th>
              {columnVisibility.address && <Th py={3}>Adresse</Th>}
              {columnVisibility.eventDate && (
                <Th py={3}>Date de l'évènement</Th>
              )}
              {columnVisibility.actions && <Th py={3}>Actions</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td colSpan={7} textAlign="center">
                  <Spinner size="xl" />
                </Td>
              </Tr>
            ) : (
              data.map((event, index) => {
                let stats = null;
                if (event._id !== undefined) {
                  stats = presenceDpaeStats[event._id];
                }
                const presenceText = stats
                  ? `${stats.totalPresent}/${stats.totalVacataires}`
                  : "";
                const dpaeText =
                  stats && stats.totalPresent
                    ? `${stats.totalDpaeValid}/${stats.totalPresent}`
                    : "";
                const contractText =
                  stats && stats.totalPresent
                    ? `${stats.totalContractValid}/${stats.totalPresent}`
                    : "";
                const presenceColor =
                  stats && stats.totalPresent === stats.totalVacataires
                    ? "green"
                    : "#E77300";
                const dpaeColor =
                  stats && stats.totalDpaeValid === stats.totalPresent
                    ? "green"
                    : "#E77300";
                const contractColor =
                  stats && stats.totalContractValid === stats.totalPresent
                    ? "green"
                    : "#E77300";

                return (
                  <React.Fragment key={event._id}>
                    <Tr>
                      {columnVisibility.name && (
                        <Td
                          isTruncated
                          maxWidth={columnVisibility.name ? "200px" : "100px"}
                          onClick={() =>
                            setOpenIndex(openIndex === index ? null : index)
                          }
                          _hover={{
                            bg: "gray.100",
                            cursor: "pointer",
                            transition: "background-color 0.3s",
                          }}
                        >
                          {event.name_event}
                        </Td>
                      )}
                      <Td color={presenceColor}>{presenceText}</Td>
                      <Td color={contractColor}>{contractText}</Td>
                      <Td color={dpaeColor}>{dpaeText}</Td>
                      {columnVisibility.address && (
                        <Td
                          isTruncated
                          maxWidth={
                            columnVisibility.address ? "150px" : "100px"
                          }
                        >
                          <Tooltip
                            label={
                              event.place
                                ? event.place.name
                                : "Pas d'adresse spécifiée"
                            }
                            placement="top"
                            hasArrow
                          >
                            <Box isTruncated>
                              {event.place
                                ? event.place.name
                                : "Pas d'adresse spécifiée"}
                            </Box>
                          </Tooltip>
                        </Td>
                      )}
                      {columnVisibility.eventDate && (
                        <Td
                          isTruncated
                          maxWidth={
                            columnVisibility.eventDate ? "250px" : "200px"
                          }
                        >
                          {event.date_event
                            ? new Date(
                                event.date_event * 1000
                              ).toLocaleDateString("fr-FR", {
                                weekday: "long",
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                              })
                            : "Aucune date d'évènement spécifiée"}
                        </Td>
                      )}
                      <Td
                        isTruncated
                        maxWidth={columnVisibility.actions ? "350px" : "250px"}
                      >
                        <HotKeys
                          keyMap={keyMap}
                          handlers={createHandlers(event)}
                        >
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              isDisabled={userRole == Roles.LimitedAdmin}
                              aria-label="Plus d'options"
                              icon={<FaEllipsisV />}
                              variant="outline"
                              mr={2}
                            />
                            <MenuList>
                              <MenuItem
                                icon={<FaDownload />}
                                isDisabled={userRole == Roles.LimitedAdmin}
                                onClick={() => generateEventXLSX(event)}
                                style={{ color: "green" }}
                                command="Ctrl+D"
                              >
                                Télécharger le tableau
                              </MenuItem>
                              <MenuItem
                                icon={<FaFileSignature />}
                                isDisabled={userRole == Roles.LimitedAdmin}
                                style={{ color: "orange" }}
                                onClick={() =>
                                  openDpaeModal(event._id ? event._id : "")
                                }
                                command="Ctrl+G"
                              >
                                Génération des DPAE
                              </MenuItem>
                              <MenuItem
                                icon={<FaEdit />}
                                style={{ color: "blue" }}
                                isDisabled={userRole == Roles.LimitedAdmin}
                                command="Ctrl+E"
                                onClick={() => openEditEvent(event)}
                              >
                                Modifier
                              </MenuItem>
                              <MenuItem
                                icon={<FaTrash />}
                                style={{ color: "red" }}
                                isDisabled={userRole == Roles.LimitedAdmin}
                                onClick={() =>
                                  event._id && openDeleteModal(event._id)
                                }
                              >
                                Supprimer
                              </MenuItem>
                            </MenuList>
                          </Menu>
                          {isLargerThan1280 && (
                            <IconButton
                              ref={btnRef}
                              colorScheme="teal"
                              onClick={() =>
                                setOpenIndex(openIndex === index ? null : index)
                              }
                              icon={
                                openIndex === index ? (
                                  <FaChevronUp />
                                ) : (
                                  <FaChevronDown />
                                )
                              }
                              aria-label="Ouvrir les détails"
                            />
                          )}
                          {!isLargerThan1280 && (
                            <IconButton
                              ref={btnRef}
                              colorScheme="teal"
                              onClick={() => {
                                onOpen();
                                setSelectedEvent(event);
                              }}
                              icon={<FaEye />}
                              aria-label="View details"
                            />
                          )}
                        </HotKeys>
                      </Td>
                    </Tr>
                    {openIndex === index && (
                      <Tr>
                        <Td colSpan={8}>
                          <Box width="100%">
                            <motion.div
                              initial={{ maxHeight: 0 }}
                              animate={{
                                maxHeight: openIndex === index ? 300 : 0,
                              }}
                              transition={{ duration: 0.5, ease: "easeInOut" }}
                              style={{
                                overflowY: "auto",
                              }}
                            >
                              <Table variant="simple" width="100%">
                                <Thead>
                                  <Tr>
                                    <Th width="20%">Service</Th>
                                    <Th width="10%">Présence</Th>
                                    <Th width="10%">Contrat</Th>
                                    <Th width="40%">DPAE</Th>
                                    <Th width="20%">Actions</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {event.coop && event.coop.length > 0
                                    ? event.coop.map(
                                        (service, serviceIndex) => {
                                          const stats =
                                            serviceStats[
                                              `${event._id}_${service.name}`
                                            ];
                                          if (!stats && event._id)
                                            fetchServiceStats(
                                              event._id,
                                              service.name
                                            );

                                          const servicePresenceText = stats
                                            ? `${stats.totalPresent}/${stats.totalVacataires}`
                                            : "";
                                          const serviceDpaeText =
                                            stats && stats.totalPresent
                                              ? `${stats.totalDpaeValid}/${stats.totalPresent}`
                                              : "";
                                          const serviceContractText =
                                            stats && stats.totalPresent
                                              ? `${stats.totalContractValid}/${stats.totalPresent}`
                                              : "";
                                          const servicePresenceColor =
                                            stats &&
                                            stats.totalPresent ===
                                              stats.totalVacataires
                                              ? "green"
                                              : "#E77300";
                                          const serviceDpaeColor =
                                            stats &&
                                            stats.totalDpaeValid ===
                                              stats.totalPresent
                                              ? "green"
                                              : "#E77300";
                                          const serviceContractColor =
                                            stats &&
                                            stats.totalContractValid ===
                                              stats.totalPresent
                                              ? "green"
                                              : "#E77300";

                                          return (
                                            <Tr key={serviceIndex}>
                                              <Td>{service.name}</Td>
                                              <Td
                                                style={{
                                                  color: servicePresenceColor,
                                                }}
                                              >
                                                {servicePresenceText}
                                              </Td>
                                              <Td
                                                style={{
                                                  color: serviceContractColor,
                                                }}
                                              >
                                                {serviceContractText}
                                              </Td>
                                              <Td
                                                style={{
                                                  color: serviceDpaeColor,
                                                }}
                                              >
                                                {serviceDpaeText}
                                              </Td>
                                              <Td>
                                                <HStack spacing="1">
                                                  <Tooltip
                                                    label={
                                                      "Télécharger le tableau"
                                                    }
                                                    placement="top"
                                                    hasArrow
                                                  >
                                                    <IconButton
                                                      id={`tableau${serviceIndex}`}
                                                      isDisabled={
                                                        userRole ==
                                                        Roles.LimitedAdmin
                                                      }
                                                      color="green"
                                                      onClick={() =>
                                                        generateServiceXLSX(
                                                          service,
                                                          event
                                                        )
                                                      }
                                                      icon={
                                                        <FaDownload fontSize="1.25rem" />
                                                      }
                                                      variant="ghost"
                                                      aria-label="Télécharger le tableau"
                                                    />
                                                  </Tooltip>
                                                  <Tooltip
                                                    label={"Accéder au service"}
                                                    placement="top"
                                                    hasArrow
                                                  >
                                                    <IconButton
                                                      id={`accessService${serviceIndex}`}
                                                      color="secondary"
                                                      onClick={() => {
                                                        if (
                                                          event._id &&
                                                          service.name
                                                        ) {
                                                          navigateZone(
                                                            event._id,
                                                            service.name
                                                          );
                                                        } else {
                                                          console.error(
                                                            "ID de l'événement ou nom du service manquant"
                                                          );
                                                        }
                                                      }}
                                                      icon={
                                                        <FaClipboardList fontSize="1.25rem" />
                                                      }
                                                      variant="ghost"
                                                      aria-label="Edit service"
                                                    />
                                                  </Tooltip>
                                                  <Tooltip
                                                    label={
                                                      "Supprimer le service"
                                                    }
                                                    isDisabled={
                                                      userRole ==
                                                      Roles.LimitedAdmin
                                                    }
                                                    placement="top"
                                                    hasArrow
                                                  >
                                                    <IconButton
                                                      id={`deleteService${serviceIndex}`}
                                                      color="red"
                                                      isDisabled={
                                                        userRole ==
                                                        Roles.LimitedAdmin
                                                      }
                                                      onClick={() => {
                                                        if (
                                                          event._id &&
                                                          service._id
                                                        ) {
                                                          deleteCoop(
                                                            event._id,
                                                            service._id
                                                          );
                                                        }
                                                      }}
                                                      icon={
                                                        <DeleteIcon fontSize="1.25rem" />
                                                      }
                                                      variant="ghost"
                                                      aria-label="Delete service"
                                                    />
                                                  </Tooltip>
                                                </HStack>
                                              </Td>
                                            </Tr>
                                          );
                                        }
                                      )
                                    : null}
                                  <Tr
                                    _hover={{
                                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => openServiceModal(event)}
                                  >
                                    <Td
                                      colSpan={4}
                                      color="gray.500"
                                      fontWeight="light"
                                      fontStyle="italic"
                                    >
                                      Ajouter un nouveau service
                                    </Td>
                                    <Td>
                                      <IconButton
                                        id="addService"
                                        color="blue"
                                        onClick={() => openServiceModal(event)}
                                        icon={<FaPlus fontSize="1.25rem" />}
                                        variant="ghost"
                                        aria-label="Add new service"
                                      />
                                    </Td>
                                  </Tr>
                                </Tbody>
                              </Table>
                            </motion.div>
                          </Box>
                        </Td>
                      </Tr>
                    )}
                  </React.Fragment>
                );
              })
            )}
          </Tbody>
        </Table>
      </Box>
      <Box mt={4} style={{ display: "flex", justifyContent: "flex-end" }}>
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          onPageChange={onPageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageClassName={"page"}
          previousClassName={"previous"}
          nextClassName={"next"}
          disabledClassName={"disabled"}
        />
        <style>{`
          .pagination {
            display: flex;
            justify-content: center;
            list-style-type: none;
            padding: 0;
          }
          .pagination .page,
          .pagination .previous,
          .pagination .next,
          .pagination .break-me {
            margin: 0 5px;
            padding: 5px 10px;
            border: 1px solid #ddd;
            cursor: pointer;
          }
          .pagination .active {
            background-color: #007bff;
            color: white;
            border-color: #007bff;
          }
          .pagination .disabled {
            color: #ddd;
            cursor: not-allowed;
          }
        `}</style>
      </Box>

      {!isLargerThan1280 && (
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={() => {
            onClose();
            setSelectedEvent(null);
          }}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              {selectedEvent ? selectedEvent.name_event : ""}
            </DrawerHeader>
            <DrawerBody>
              {selectedEvent ? (
                <EventDetailsDrawerBody event={selectedEvent} />
              ) : (
                <Text>Aucun événement sélectionné</Text>
              )}
            </DrawerBody>
            <DrawerFooter>
              <SimpleGrid columns={{ base: 2, sm: 2, md: 2 }} spacing={2}>
                {selectedEvent?.coop?.map((service: any, index: number) => (
                  <Button
                    key={index}
                    variant="outline"
                    color="secondary"
                    onClick={() => {
                      if (selectedEvent?._id && service.name) {
                        navigateZone(selectedEvent._id, service.name);
                      } else {
                        console.error(
                          "ID de l'événement ou nom du service manquant"
                        );
                      }
                    }}
                  >
                    {service.name}
                  </Button>
                ))}
              </SimpleGrid>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  );
};
