import { ChevronDownIcon, DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import ExcelJS from "exceljs";
import { useContext, useState } from "react";
import { getVacataireMatricule } from "../../../api/ApiVacataire";
import { AuthContext } from "../../../utils/context";
import { PersonnelRolesLabels } from "../../../utils/types/EnumPost";
import { Roles } from "../../../utils/types/roles.interface";
import { STATUS_ACCEPT } from "./EvenementZones";

interface ZoneHeaderProps {
  eventDetails: any;
  eventId: string;
  service: string;
  zone: any;
  onDeleteZone: (
    eventId: string,
    zoneName: string,
    serviceName: string
  ) => void;
}

export const ZoneHeader: React.FC<ZoneHeaderProps> = ({
  eventDetails,
  eventId,
  service,
  zone,
  onDeleteZone,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { userRole } = useContext(AuthContext);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  /*
  const downloadTable = () => {
    const data = zone.vacataires.map((vacataire: any) => ({
      NOM: vacataire.name,
      PRENOM: vacataire.firstname,
      POSTE: "", // A remplir selon votre data
      "HEURE DE CONVOCATION": "", // A remplir
      'HEURE D\'ARRIVEE OU MENTION "ABSENT"': "", // A remplir
      "SIGNATURE A L'ARRIVEE": "",
      "TEMPS DE PAUSE (non rémunéré)": "",
      "HEURE DE FIN": "", // Ajoutez l'heure de fin ici si vous l'avez
      "SIGNATURE AU DEPART": "",
      "PRESENT(E) EVENEMENT DU": "", // A remplir
    }));

    // Ajout du titre et des noms de colonnes
    const title = { NOM: `Nom de la zone: ${zone.name}` };
    const header = {
      NOM: "NOM",
      PRENOM: "PRENOM",
      POSTE: "POSTE",
      "HEURE DE CONVOCATION": "HEURE DE CONVOCATION",
      'HEURE D\'ARRIVEE OU MENTION "ABSENT"':
        'HEURE D\'ARRIVEE OU MENTION "ABSENT"',
      "SIGNATURE A L'ARRIVEE": "SIGNATURE A L'ARRIVEE",
      "TEMPS DE PAUSE (non rémunéré)": "TEMPS DE PAUSE (non rémunéré)",
      "HEURE DE FIN": "HEURE DE FIN",
      "SIGNATURE AU DEPART": "SIGNATURE AU DEPART",
      "PRESENT(E) EVENEMENT DU": "PRESENT(E) EVENEMENT DU",
    };

    data.unshift(header, title);

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    // Réglage de la largeur des colonnes
    ws["!cols"] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 25 },
      { wch: 35 },
      { wch: 25 },
      { wch: 30 },
      { wch: 20 },
      { wch: 25 },
      { wch: 25 },
    ];

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ZoneSheet");
    XLSX.writeFile(wb, `${zone.name}.xlsx`);
  };
  */

  const generateXLSX = async () => {
    console.log("on va dl");

    const imageEtms = "/images/logo/ETMS.png";
    const imageHospitality = "/images/logo/TH.png";
    async function fetchImageAsBase64(url: string): Promise<string> {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }
    try {
      const vacatairesFiltres = zone.vacataires.filter(
        (vacataire: any) => vacataire.status === STATUS_ACCEPT
      );
      const workbook = new ExcelJS.Workbook();

      const worksheet = workbook.addWorksheet("Feuille de Margement");

      const headerData = [
        ["Événement", eventDetails?.name_event],
        [
          "Date",
          eventDetails?.date_event
            ? new Date(eventDetails.date_event * 1000).toLocaleDateString(
                "fr-FR",
                {
                  weekday: "long",
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                }
              )
            : "Aucune date d'évènement spécifiée",
        ],
        [
          "Lieu",
          eventDetails?.place
            ? eventDetails.place.name
            : "Aucune adresse d'évènement spécifiée",
        ],
        ["Service", service],
        ["Zone", zone?.name ? zone.name : "Aucune zone spécifiée"],
      ];

      //Image select
      const selectedImageUrl =
        service === "Sécurité" ? imageEtms : imageHospitality;

      const selectedImageData = await fetchImageAsBase64(selectedImageUrl);

      const imageId = workbook.addImage({
        base64: selectedImageData,
        extension: "png",
      });
      let originalWidth = 148;
      let originalHeight = 95;

      // Doubler la hauteur et ajuster la largeur pour conserver le ratio
      let newHeight = originalHeight * 2;
      let newWidth = originalWidth * (newHeight / originalHeight);

      // Ajouter l'image à la feuille de calcul en commençant à la colonne 8
      worksheet.addImage(imageId, {
        tl: { col: 9, row: 0 },
        ext: { width: newWidth, height: newHeight },
      });
      headerData.forEach((row, rowIndex) => {
        row.forEach((cell, cellIndex) => {
          const excelCell = worksheet.getCell(rowIndex + 1, cellIndex + 1);
          excelCell.alignment = {
            wrapText: true,
            horizontal: "center",
            vertical: "middle",
          };
          excelCell.value = cell;
          if (cellIndex === 0) {
            excelCell.font = { color: { argb: "FFFF0000" } }; // Rouge
          } else {
            excelCell.font = { bold: true };
          }
        });
      });

      const spacerRowIndex = headerData.length + 1;
      worksheet.addRow([]);

      let mappedVacataires = [];

      if (vacatairesFiltres && vacatairesFiltres.length > 0) {
        for (const vacataire of vacatairesFiltres) {
          const convertTimestampToFrenchHour = (timestamp: number) => {
            const date = new Date(timestamp * 1000); // Multiplier par 1000 car on suppose que le timestamp est en secondes
            let timeString = date.toLocaleTimeString("fr-FR", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            });
            timeString = timeString.replace(":", "h");
            return timeString;
          };
          const matricule = await getVacataireMatricule(
            vacataire.vacataireId,
            service
          );
          const startHourFrench = vacataire.startHour
            ? convertTimestampToFrenchHour(vacataire.startHour)
            : "Non renseigné";
          const roleLabel =
            PersonnelRolesLabels[
              vacataire.fonction as keyof typeof PersonnelRolesLabels
            ] || "Non renseigné";
          mappedVacataires.push([
            matricule || "Non renseigné",
            vacataire.name,
            vacataire.firstname,
            roleLabel,
            startHourFrench,
            "",
            "",
            "",
            "",
            "",
            "",
          ]);
        }
      } else {
        mappedVacataires = [["Aucun vacataire défini pour cette zone"]];
      }

      const data = [
        [
          "MATRICULE",
          "NOM",
          "PRÉNOM",
          "POSTE",
          "HEURE DE CONVOCATION",
          "HEURE RÉELLE D'ARRIVEE",
          "SIGNATURE À L'ARRIVÉE (SI RETARD)",
          "TEMPS DE PAUSE (NON RÉMUNÉRÉ)",
          "HEURE DE FIN",
          "SIGNATURE AU DEPART",
          "PROCHAIN ÉVENÈMENT    ................",
        ],
        ...mappedVacataires,
      ];
      console.log(data);

      data.forEach((row, rowIndex) => {
        row.forEach(
          (
            cell:
              | string
              | number
              | boolean
              | Date
              | ExcelJS.CellErrorValue
              | ExcelJS.CellRichTextValue
              | ExcelJS.CellHyperlinkValue
              | ExcelJS.CellFormulaValue
              | ExcelJS.CellSharedFormulaValue
              | null
              | undefined,
            cellIndex: number
          ) => {
            const excelCell = worksheet.getCell(
              rowIndex + spacerRowIndex + 1,
              cellIndex + 1
            );
            excelCell.value = cell;

            if (rowIndex === 0) {
              excelCell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFD3D3D3" },
              };
              excelCell.alignment = {
                wrapText: true,
                horizontal: "center",
                vertical: "middle",
              };
            }
            excelCell.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
          }
        );
      });

      worksheet.columns = [
        { width: 12 },
        { width: 25 },
        { width: 20 },
        { width: 20 },
        { width: 15 },
        { width: 15 },
        { width: 20 },
        { width: 15 },
        { width: 15 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
      ];
      for (let i = 0; i < 5; i++) {
        let newRow = worksheet.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]);
        newRow.eachCell((cell, colNumber) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      }
      worksheet.eachRow({ includeEmpty: false }, function (row, rowNumber) {
        row.height = 60;

        row.eachCell({ includeEmpty: false }, function (cell, colNumber) {
          cell.font = {
            size: 13,
            bold: cell.font ? cell.font.bold : false,
            color: cell.font ? cell.font.color : { argb: "FF000000" },
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });

      worksheet.pageSetup = {
        paperSize: 9, //8 A3 9A4
        orientation: "portrait",
        fitToPage: true,
        fitToWidth: 1,
        fitToHeight: 0,
      };

      worksheet.pageSetup.margins = {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0.75,
        header: 0.3,
        footer: 0.3,
      };

      // Sauvegardez le fichier
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileName = `${eventDetails?.name_event || "unknownEvent"}_${
        service || "unknownService"
      }_${zone?.name || "unknownZone"}_emargement.xlsx`;

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      console.log("===> ça se dl");
    } catch (error) {
      console.error("Error generating Excel for event:", error);
    }
  };

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Heading as="h2" size="xs" color="secondary">
        {zone.name}
      </Heading>

      <Menu>
        <MenuButton
          as={Button}
          color="primary"
          rightIcon={<ChevronDownIcon />}
          isDisabled={userRole == Roles.LimitedAdmin}
        />
        <MenuList>
          <MenuItem
            icon={<DownloadIcon />}
            onClick={generateXLSX}
            style={{ color: "green" }}
          >
            Télécharger le tableau
          </MenuItem>
          {userRole != Roles.LimitedAdmin && (
            <MenuItem
              color={"red"}
              icon={<DeleteIcon color="red" />}
              onClick={handleOpenModal}
            >
              Supprimer
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmer la suppression</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Êtes-vous sûr de vouloir supprimer cette zone ?</ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={handleCloseModal}>
              Annuler
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                onDeleteZone(eventId, zone.name, service);
                handleCloseModal();
              }}
            >
              Confirmer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
