import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  ProtectedRoutes,
  PublicRoutes,
  VacatairesRoutes,
} from "../../components/Authentification/AuthRoutes";
import TemporaryLogin from "../../components/Authentification/TemporaryLogin";
import EvenementZones from "../../components/Evenements/zones/EvenementZones";
import { DepositView } from "../../views/DepotView/DepotView";
import EvenementsView from "../../views/EvenementsView/EvenementsView";
import SelectTypesView from "../../views/EvenementsView/SelectTypesView";
import DocumentsVacataireView from "../../views/GuestView/VacataireDocumentView";
import VacataireProfileView from "../../views/GuestView/VacataireProfileView";
import { HelpView } from "../../views/HelpView/HelpView";
import { Login } from "../../views/LoginView";
import { NotificationView } from "../../views/NotificationView/NotificationView";
import FileViewerPage from "../../views/PageViewer/PageViewer";
import UpdateStatus from "../../views/VacataireView/ConfirmConvoc";
import VacataireView from "../../views/VacataireView/VacataireView";
import {
  CONFIRM_CONVOC_PAGE,
  DEPOSITS_PAGE,
  EVENTS_PAGE,
  HELP_PAGE,
  HOME_PAGE,
  LOGIN_PAGE,
  NOTIFICATION_PAGE,
  PUBLIC_LOGIN_PAGE,
  TEMP_WORKERS_PAGE,
  VACATAIRE_DOCUMENT_PAGE,
  VACATAIRE_PROFIL_PAGE,
  Viewing_page,
} from "./endpoints";

const Redirector: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const validationCode = pathParts[pathParts.length - 1];

    if (validationCode) {
      navigate(PUBLIC_LOGIN_PAGE.replace(":validationCode", validationCode));
    }
  }, [location, navigate]);

  return null;
};
const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path={LOGIN_PAGE} element={<Login />} />
          <Route path={PUBLIC_LOGIN_PAGE} element={<TemporaryLogin />} />{" "}
        </Route>
        <Route element={<ProtectedRoutes />}>
          <Route path={HOME_PAGE} element={<SelectTypesView />} />
          <Route path={TEMP_WORKERS_PAGE} element={<VacataireView />} />
          <Route path={EVENTS_PAGE} element={<SelectTypesView />} />
          <Route
            path={`${EVENTS_PAGE}/:eventType`}
            element={<EvenementsView />}
          />

          <Route path={DEPOSITS_PAGE} element={<DepositView />} />
          <Route path={NOTIFICATION_PAGE} element={<NotificationView />} />
          <Route path={HELP_PAGE} element={<HelpView />} />
          <Route path={Viewing_page} element={<FileViewerPage />} />

          <Route
            path={EVENTS_PAGE + "/:id/:service"}
            element={<EvenementZones />}
          />
        </Route>
        <Route element={<VacatairesRoutes />}>
          <Route
            path={VACATAIRE_PROFIL_PAGE}
            element={<VacataireProfileView />}
          />
          <Route
            path={VACATAIRE_DOCUMENT_PAGE}
            element={<DocumentsVacataireView />}
          />
          <Route path={CONFIRM_CONVOC_PAGE} element={<UpdateStatus />} />
        </Route>
        <Route path="*" element={<Redirector />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
