// Types

// paths & errors

import { LoginType, ToLoginType, UserType } from "../../utils/types";
import {
  API_CONFIRM_EMAIL,
  API_LOGIN,
  API_LOGOUT,
  API_UPDATE_EMAIL,
  API_UPDATE_PASSWORD,
  API_UPDATE_USERNAME,
  API_URL,
} from "../ApiPaths";
import {
  ERROR_CODE_INVALID,
  ERROR_EMAIL_ALREADY_TAKEN,
  ERROR_INVALID_CREDENTIALS,
  ERROR_INVALID_USERNAME,
  ERROR_UNAUTHORIZED,
  ERROR_UNKNOWN,
  ERROR_WRONG_RESPONSE,
} from "./Errors";

// axios

/*****************************/
/** AUTHENTICATION HELPERS **/
/****************************/

/**
 * Login through the API
 * @param email
 * @param password
 * @returns Token + User info bundled in a LoginType
 * @error ERROR_INVALID_CREDENTIALS
 * @error ERROR_UNKNOWN
 */
export const loginForm = (data: ToLoginType): Promise<LoginType> => {
  return fetch(API_URL + API_LOGIN, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...data,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          throw ERROR_INVALID_CREDENTIALS;
        } else {
          throw ERROR_UNKNOWN;
        }
      }
      return response.json();
    })
    .then((responseData) => {
      if (responseData) {
        // process the response to return token & UserType
        try {
          const token: string = responseData.token;
          const id = parseInt(responseData.user.id);
          const firstname = responseData.user.firstname;
          const lastname = responseData.user.name;
          const role = responseData.user.role;

          const email = responseData.user.email;
          const user: UserType = { id, firstname, lastname, email, role };

          return { token, user } as LoginType;
        } catch (error) {
          console.error("Converting data to User: FAILED >> " + error);
          throw ERROR_WRONG_RESPONSE;
        }
      } else {
        console.error("Missing Data in the response");
        throw ERROR_WRONG_RESPONSE;
      }
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};

function clearToken() {
  localStorage.removeItem("eventsid");
}

/**
 * Logout through the API
 * @returns Token + User info bundled in a LoginType
 * @error ERROR_UNAUTHORIZED
 * @error ERROR_UNKNOWN
 */
export const logoutForm = () => {
  return fetch(API_URL + API_LOGOUT, {
    credentials: "include",
  })
    .then((response) => {
      if (response.ok) {
        clearToken();
        return;
      }
      if (response.status === 401) {
        clearToken();
        throw ERROR_UNAUTHORIZED;
      }
      throw ERROR_UNKNOWN;
    })
    .catch((error) => {
      clearToken();
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};

/**
 * Confirm the email of a user with a code (sent by email)
 * @param userId
 * @param code
 * @error ERROR_CODE_INVALID
 * @error ERROR_UNKNOWN
 * @returns user info
 */
export const confirmEmail = async (userId: string, code: string) => {
  try {
    const response = await fetch(
      API_URL + API_CONFIRM_EMAIL.replace(":id", userId),
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: code,
        }),
      }
    );
    if (response.status === 403) throw ERROR_CODE_INVALID;
    return response.json();
  } catch (error) {
    console.error(error);
    throw ERROR_UNKNOWN;
  }
};

/**
 *
 * @param email
 * @error ERR_EMAIL_ALREADY_TAKEN
 * @error ERROR_UNKNOWN
 * @returns user info
 */
export const updateEmail = (email: string) => {
  return fetch(API_URL + API_UPDATE_EMAIL, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      if (data.error_key === "ERR_EMAIL_ALREADY_EXISTS") {
        throw ERROR_EMAIL_ALREADY_TAKEN;
      }
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};

/**
 * Change user's password
 * @param currentPassword
 * @param newPassword
 * @error ERROR_INVALID_CREDENTIALS
 * @error ERROR_UNKNOWN
 * @returns user info
 */
export const updatePassword = async (
  currentPassword: string,
  newPassword: string
) => {
  try {
    const response = await fetch(API_URL + API_UPDATE_PASSWORD, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        current_password: currentPassword,
        new_password: newPassword,
      }),
    });

    if (response.status === 403) {
      throw ERROR_INVALID_CREDENTIALS;
    } else if (!response.ok) {
      throw ERROR_UNKNOWN;
    }

    return await response.json();
  } catch (error) {
    console.error(error);
    throw ERROR_UNKNOWN;
  }
};

/**
 * Change user's name
 * @param username
 * @error ERROR_INVALID_USERNAME
 * @error ERROR_UNKNOWN
 * @returns user info
 */
export const updateUsername = async (username: string) => {
  try {
    const response = await fetch(API_URL + API_UPDATE_USERNAME, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: username }),
    });
    if (!response.ok) {
      if (response.status === 400) {
        throw ERROR_INVALID_USERNAME;
      } else {
        throw ERROR_UNKNOWN;
      }
    }
  } catch (error) {
    console.error(error);
    throw ERROR_UNKNOWN;
  }
};
