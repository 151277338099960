import {
  Box,
  Button,
  Divider,
  HStack,
  IconButton,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FiRefreshCw } from "react-icons/fi";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getLastNotification } from "../../../api/ApiNotification";
import { NOTIFICATION_PAGE } from "../../../utils/navigation/endpoints";

export const Notification: React.FC = () => {
  const {
    data: lastNotification,
    isError,
    isLoading,
    refetch,
  } = useQuery("lastNotification", getLastNotification);
  const navigate = useNavigate();

  const renderNotification = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (isError) {
      return (
        <Text fontSize="sm" color="red">
          Erreur du chargement des notifications
        </Text>
      );
    }

    if (lastNotification) {
      return (
        <>
          <Text fontSize="md" fontWeight="bold" color="on-accent">
            {lastNotification.title}
          </Text>
          <Text fontSize="sm" color="on-accent-muted">
            {lastNotification.description}
          </Text>
        </>
      );
    }

    return <Text fontSize="sm">Aucune action effectuée sur l'application</Text>;
  };

  return (
    <Box bg="secondary" px="4" py="5" borderRadius="lg">
      <Stack spacing="4">
        <Stack spacing="1" align="start">
          <HStack justifyContent="space-between" width="100%">
            <Text fontSize="lg" fontWeight="medium">
              Notification
            </Text>
            <IconButton
              aria-label="Refresh notifications"
              icon={<FiRefreshCw />}
              variant="ghost"
              color="on-accent-muted"
              _hover={{ color: "primary" }}
              onClick={() => refetch()}
            />
          </HStack>
          <Divider />
          {renderNotification()}
        </Stack>
        <Divider />

        <HStack spacing="3">
          <Button
            variant="link-on-accent"
            size="sm"
            color="on-accent-muted"
            onClick={() => navigate(NOTIFICATION_PAGE)}
          >
            Voir les notifications
          </Button>
        </HStack>
      </Stack>
    </Box>
  );
};
