import { Box, Image } from "@chakra-ui/react";
export const LogoEstac = () => {
  return (
    <Box display="flex">
      <Image
        margin="auto"
        src="/images/logo/logo_estac_chrome_2012_64.png"
        alt="Logo Estac"
      />
    </Box>
  );
};
