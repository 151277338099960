import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useState } from "react";
import { ManageVacataire } from "./ManageVacataire";

export const TabComponents = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Tabs
      size="md"
      variant="enclosed"
      color="primary"
      onChange={(index) => setActiveTab(index)}
    >
      <TabList>
        <Tab fontWeight="bold">Salariés présents</Tab>
        <Tab fontWeight="bold">Salariés sortis</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <ManageVacataire archiveState={false} activeTab={activeTab} />
        </TabPanel>
        <TabPanel>
          <ManageVacataire archiveState={true} activeTab={activeTab} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
