export enum PersonnelCategory {
  ACCUEIL_SERVICE = "ACCUEIL_SERVICE",
  REGISSEUR = "REGISSEUR",
  MINEUR = "MINEUR",
  SECURITE = "SECURITE",
}

export const PersonnelCategoryLabels: Record<PersonnelCategory, string> = {
  [PersonnelCategory.ACCUEIL_SERVICE]:
    "Personnel d'Accueil et de Service Client",
  [PersonnelCategory.REGISSEUR]: "Coordinateur",
  [PersonnelCategory.MINEUR]: "Mineurs",
  [PersonnelCategory.SECURITE]: "Sécurité",
};

export enum PersonnelRoles {
  Agent_daccueil = "Agent_daccueil",
  Aide_intendance = "Aide_intendance",
  Conseiller_billetterie = "Conseiller_billetterie",
  Conseiller_boutique = "Conseiller_boutique",
  Intervention_Controleur_video = "Intervention_Controleur_video",
  Hote_daccueil_et_service = "Hote_daccueil_et_service",
  Hote_daccueil_et_service_buvette = "Hote_daccueil_et_service_buvette",
  Hote_danimation = "Hote_danimation",
  Coordinateur_Billetterie = "Coordinateur_Billeterie",
  Coordinateur_Buvette = "Coordinateur_Buvette",
  Coordinateur_Hote_daccueil_et_service = "Coordinateur_Hote_daccueil_et_service",
  Coordinateur_Hote_danimation = "Coordinateur_Hote_danimation",
  Agent_daccueil_mineur = "Agent_daccueil_mineur",
  Aide_intendance_mineur = "Aide_intendance_mineur",
  Conseiller_billetterie_mineur = "Conseiller_billetterie_mineur",
  Conseiller_boutique_mineur = "Conseiller_boutique_mineur",
  Intervention_Hote_daccueil_hospitalises_mineur = "Intervention_Hote_daccueil_hospitalises_mineur",
  Intervention_Hote_danimation_mineur = "Intervention_Hote_danimation_mineur",
  Agent_de_securite = "Agent_de_securite",
  Intervention_SSIAP_1 = "Intervention_SSIAP_1",
  Intervention_SSIAP_2 = "Intervention_SSIAP_2",
  Intervention_SSIAP_3 = "Intervention_SSIAP_3",
  Intervention_Directeur_securite = "Intervention_Directeur_securite",
  Chef_securite = "Chef_securite",
  Deplacement_securite = "Deplacement_securite",
  NOT_DEFINED = "",
}

export enum PersonnelRolesHorsMatch {
  Agent_daccueil = "Agent_daccueil",
  Aide_intendance = "Aide_intendance",
  Conseiller_billetterie = "Conseiller_billetterie",
  Conseiller_boutique = "Conseiller_boutique",
  Hote_daccueil_et_service = "Hote_daccueil_et_service",
  Hote_daccueil_et_service_buvette = "Hote_daccueil_et_service_buvette",
  Hote_danimation = "Hote_danimation",
  Intervention_Controleur_video = "Intervention_Controleur_video",

  Coordinateur_Billetterie = "Coordinateur_Billeterie",
  Coordinateur_Buvette = "Coordinateur_Buvette",
  Coordinateur_Hote_daccueil_et_service = "Coordinateur_Hote_daccueil_et_service",
  Coordinateur_Hote_danimation = "Coordinateur_Hote_danimation",
  Agent_daccueil_mineur = "Agent_daccueil_mineur",
  Aide_intendance_mineur = "Aide_intendance_mineur",
  Conseiller_billetterie_mineur = "Conseiller_billetterie_mineur",
  Conseiller_boutique_mineur = "Conseiller_boutique_mineur",
  Intervention_Hote_daccueil_hospitalises_mineur = "Intervention_Hote_daccueil_hospitalises_mineur",
  Intervention_Hote_danimation_mineur = "Intervention_Hote_danimation_mineur",
  Agent_de_securite = "Agent_de_securite",
  Intervention_SSIAP_1 = "Intervention_SSIAP_1",
  Intervention_SSIAP_2 = "Intervention_SSIAP_2",
  Intervention_SSIAP_3 = "Intervention_SSIAP_3",
  Intervention_Directeur_securite = "Intervention_Directeur_securite",
  Chef_securite = "Chef_securite",
  Deplacement_securite = "Deplacement_securite",
  NOT_DEFINED = "",
}

export const PersonnelRolesLabels: Record<PersonnelRoles, string> = {
  [PersonnelRoles.Agent_daccueil]: "Agent d’accueil",
  [PersonnelRoles.Aide_intendance]: "Aide intendance",
  [PersonnelRoles.Conseiller_billetterie]: "Conseiller-ère billetterie",
  [PersonnelRoles.Conseiller_boutique]: "Conseiller-ère boutique ",
  [PersonnelRoles.Hote_daccueil_et_service]: "Hôte-esse d'accueil et service ",
  [PersonnelRoles.Hote_daccueil_et_service_buvette]:
    "Hôte-esse d'accueil et service buvette",
  [PersonnelRoles.Hote_danimation]: "Hôte-esse d'animation",
  [PersonnelRoles.Intervention_Controleur_video]: "Contrôleur-se Vidéos",
  [PersonnelRoles.Coordinateur_Billetterie]: "Coordinateur_Billeterie",
  [PersonnelRoles.Coordinateur_Buvette]: "Coordinateur_Buvette",
  [PersonnelRoles.Coordinateur_Hote_daccueil_et_service]:
    "Coordinateur_Hote_daccueil_et_service",
  [PersonnelRoles.Coordinateur_Hote_danimation]: "Coordinateur_Hote_danimation",
  [PersonnelRoles.Agent_daccueil_mineur]: "Agent d'accueil mineur(e)",
  [PersonnelRoles.Aide_intendance_mineur]: "Aide intendance mineur(e)",
  [PersonnelRoles.Conseiller_billetterie_mineur]:
    "Conseiller-ère billetterie mineur(e)",
  [PersonnelRoles.Conseiller_boutique_mineur]:
    "Conseiller-ère boutique mineur(e)",
  [PersonnelRoles.Intervention_Hote_daccueil_hospitalises_mineur]:
    "Hôte-esse d'accueil hospitalités mineur(e)",
  [PersonnelRoles.Intervention_Hote_danimation_mineur]:
    "Hôte-esse d'animation mineur(e)",
  [PersonnelRoles.Agent_de_securite]: "Agent de sécurité",
  [PersonnelRoles.Intervention_SSIAP_1]: "SSIAP 1",
  [PersonnelRoles.Intervention_SSIAP_2]: "SSIAP 2",
  [PersonnelRoles.Intervention_SSIAP_3]: "SSIAP 3",
  [PersonnelRoles.Intervention_Directeur_securite]: "Directeur securité",
  [PersonnelRoles.Chef_securite]: "Chef Agent de sécurité",
  [PersonnelRoles.Deplacement_securite]: "Déplacement - Agent de sécurité",
  [PersonnelRoles.NOT_DEFINED]: "",
};

export const categoryToRoles: Record<PersonnelCategory, PersonnelRoles[]> = {
  [PersonnelCategory.ACCUEIL_SERVICE]: [
    PersonnelRoles.Agent_daccueil,
    PersonnelRoles.Aide_intendance,
    PersonnelRoles.Conseiller_billetterie,
    PersonnelRoles.Conseiller_boutique,
    PersonnelRoles.Hote_daccueil_et_service,
    PersonnelRoles.Hote_daccueil_et_service_buvette,
    PersonnelRoles.Hote_danimation,
    PersonnelRoles.Intervention_Controleur_video,
  ],
  [PersonnelCategory.REGISSEUR]: [
    PersonnelRoles.Coordinateur_Billetterie,
    PersonnelRoles.Coordinateur_Buvette,
    PersonnelRoles.Coordinateur_Hote_daccueil_et_service,
    PersonnelRoles.Coordinateur_Hote_danimation,
  ],
  [PersonnelCategory.MINEUR]: [
    PersonnelRoles.Agent_daccueil_mineur,
    PersonnelRoles.Aide_intendance_mineur,
    PersonnelRoles.Conseiller_billetterie_mineur,
    PersonnelRoles.Conseiller_boutique_mineur,
    PersonnelRoles.Intervention_Hote_daccueil_hospitalises_mineur,
    PersonnelRoles.Intervention_Hote_danimation_mineur,
  ],
  [PersonnelCategory.SECURITE]: [
    PersonnelRoles.Agent_de_securite,
    PersonnelRoles.Chef_securite,
    PersonnelRoles.Intervention_SSIAP_1,
    PersonnelRoles.Intervention_SSIAP_2,
    PersonnelRoles.Intervention_SSIAP_3,
    PersonnelRoles.Deplacement_securite,
    PersonnelRoles.Intervention_Directeur_securite,
  ],
};

export const PersonnelRolesLabelsHorsMatch: Record<
  PersonnelRolesHorsMatch,
  string
> = {
  [PersonnelRolesHorsMatch.Agent_daccueil]: "Agent d’accueil",
  [PersonnelRolesHorsMatch.Aide_intendance]: "Aide intendance",
  [PersonnelRolesHorsMatch.Conseiller_billetterie]:
    "Conseiller-ère billetterie",
  [PersonnelRolesHorsMatch.Conseiller_boutique]: "Conseiller-ère boutique ",
  [PersonnelRolesHorsMatch.Hote_daccueil_et_service]:
    "Hôte-esse d'accueil et service ",
  [PersonnelRolesHorsMatch.Hote_daccueil_et_service_buvette]:
    "Hôte-esse d'accueil et service buvette",
  [PersonnelRolesHorsMatch.Hote_danimation]: "Hôte-esse d'animation",
  [PersonnelRolesHorsMatch.Intervention_Controleur_video]:
    "Contrôleur-se Vidéos",

  [PersonnelRolesHorsMatch.Coordinateur_Billetterie]: "Coordinateur_Billeterie",
  [PersonnelRolesHorsMatch.Coordinateur_Buvette]: "Coordinateur_Buvette",
  [PersonnelRolesHorsMatch.Coordinateur_Hote_daccueil_et_service]:
    "Coordinateur_Hote_daccueil_et_service",
  [PersonnelRolesHorsMatch.Coordinateur_Hote_danimation]:
    "Coordinateur_Hote_danimation",
  [PersonnelRolesHorsMatch.Agent_daccueil_mineur]: "Agent d'accueil mineur(e)",
  [PersonnelRolesHorsMatch.Aide_intendance_mineur]: "Aide intendance mineur(e)",
  [PersonnelRolesHorsMatch.Conseiller_billetterie_mineur]:
    "Conseiller-ère billetterie mineur(e)",
  [PersonnelRolesHorsMatch.Conseiller_boutique_mineur]:
    "Conseiller-ère boutique mineur(e)",
  [PersonnelRolesHorsMatch.Intervention_Hote_daccueil_hospitalises_mineur]:
    "Hôte-esse d'accueil hospitalités mineur(e)",
  [PersonnelRolesHorsMatch.Intervention_Hote_danimation_mineur]:
    "Hôte-esse d'animation mineur(e)",
  [PersonnelRolesHorsMatch.Agent_de_securite]: "Agent de sécurité",
  [PersonnelRolesHorsMatch.Intervention_SSIAP_1]: "SSIAP 1",
  [PersonnelRolesHorsMatch.Intervention_SSIAP_2]: "SSIAP 2",
  [PersonnelRolesHorsMatch.Intervention_SSIAP_3]: "SSIAP 3",
  [PersonnelRolesHorsMatch.Intervention_Directeur_securite]:
    "Directeur securité",
  [PersonnelRolesHorsMatch.Chef_securite]: "Chef Agent de sécurité",
  [PersonnelRolesHorsMatch.Deplacement_securite]:
    "Déplacement - Agent de sécurité",
  [PersonnelRolesHorsMatch.NOT_DEFINED]: "",
};
//Todo Hors match
export const categoryToRolesHorsMatch: Record<
  PersonnelCategory,
  PersonnelRolesHorsMatch[]
> = {
  [PersonnelCategory.ACCUEIL_SERVICE]: [
    PersonnelRolesHorsMatch.Agent_daccueil,
    PersonnelRolesHorsMatch.Aide_intendance,
    PersonnelRolesHorsMatch.Conseiller_billetterie,
    PersonnelRolesHorsMatch.Conseiller_boutique,
    PersonnelRolesHorsMatch.Hote_daccueil_et_service,
    PersonnelRolesHorsMatch.Hote_daccueil_et_service_buvette,
    PersonnelRolesHorsMatch.Hote_danimation,
    PersonnelRolesHorsMatch.Intervention_Controleur_video,
  ],
  [PersonnelCategory.REGISSEUR]: [
    PersonnelRolesHorsMatch.Coordinateur_Billetterie,
    PersonnelRolesHorsMatch.Coordinateur_Buvette,
    PersonnelRolesHorsMatch.Coordinateur_Hote_daccueil_et_service,
    PersonnelRolesHorsMatch.Coordinateur_Hote_danimation,
  ],
  [PersonnelCategory.MINEUR]: [
    PersonnelRolesHorsMatch.Agent_daccueil_mineur,
    PersonnelRolesHorsMatch.Aide_intendance_mineur,
    PersonnelRolesHorsMatch.Conseiller_billetterie_mineur,
    PersonnelRolesHorsMatch.Conseiller_boutique_mineur,
    PersonnelRolesHorsMatch.Intervention_Hote_daccueil_hospitalises_mineur,
    PersonnelRolesHorsMatch.Intervention_Hote_danimation_mineur,
  ],
  [PersonnelCategory.SECURITE]: [
    PersonnelRolesHorsMatch.Agent_de_securite,
    PersonnelRolesHorsMatch.Chef_securite,
    PersonnelRolesHorsMatch.Intervention_SSIAP_1,
    PersonnelRolesHorsMatch.Intervention_SSIAP_2,
    PersonnelRolesHorsMatch.Intervention_SSIAP_3,
    PersonnelRolesHorsMatch.Deplacement_securite,
    PersonnelRolesHorsMatch.Intervention_Directeur_securite,
  ],
};
// VacataireStatus.ts (ou dans le même fichier que votre composant)
export enum VacataireStatus {
  NotRegistered = "NotRegistered",
  RegisteredNotInvited = "RegisteredNotInvited",
  AwaitingResponse = "AwaitingResponse",
  InvitationAcceptedContractSigned = "InvitationAcceptedContractSigned",
  InvitationDeclined = "InvitationDeclined",
}
