import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import pica from "pica";
import React, { useContext, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { AiOutlineDelete, AiOutlineFile } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { API_UPLOAD, API_URL, API_VACATAIRE } from "../../../../api";
import { AuthContext } from "../../../../utils/context";
import { Roles } from "../../../../utils/types/roles.interface";
import { LoaderSpinner } from "../../../_shared/loader/CustomSpinner";

interface DocumentUploadProps {
  name: string;
  label: string;
  id: string;
}

const DocumentUpload: React.FC<DocumentUploadProps> = ({ name, label, id }) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();
  const file = watch(name);

  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const { userRole } = useContext(AuthContext);
  const toast = useToast();
  const navigate = useNavigate();

  const [filePath, setFilePath] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(false); // Ajouter un état pour le chargement

  const [fileDownloaded, setFileDownloaded] = React.useState(false); // Nouvel état pour suivre le téléchargement
  const [refresh, setRefresh] = React.useState(false);

  const fetchFileData = () => {
    fetch(`${API_URL}${API_VACATAIRE}/download/${id}/${name}`, {
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          setFilePath(`${API_URL}${API_VACATAIRE}/download/${id}/${name}`);
        } else {
          throw new Error("Erreur : Le fichier n'existe pas");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onDrop = React.useCallback(
    async (acceptedFiles: any) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append(name, acceptedFiles[0]);

      fetch(`${API_URL}${API_VACATAIRE}${API_UPLOAD}/${name}/${id}`, {
        method: "POST",
        body: formData,
        credentials: "include",
      })
        .then((response) => {
          if (response.ok) {
            fetchFileData();
            toast({
              title: "Succès !",
              description: "Votre fichier a bien été téléchargé.",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            toast({
              title: "Oups !",
              description:
                "Il semble y avoir eu un problème avec le serveur. Assurez-vous que l'image n'est pas trop volumineuse et réessayez.",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          toast({
            title: "Erreur de connexion",
            description:
              "Nous ne parvenons pas à contacter le serveur. Vérifiez votre connexion et réessayez.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          setIsLoading(false);
        });
    },
    [setValue, name, id] // Dépendances
  );

  React.useEffect(() => {
    fetchFileData(); // Appelle la fonction au montage du composant
  }, [id, name, refresh]);

  const deleteFile = () => {
    setIsLoading(true);

    fetch(`${API_URL}${API_VACATAIRE}/delete/${id}/${name}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setFilePath("");
        setValue(name, "");
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const resizeImage = (file: File): Promise<Blob | null> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        // Utiliser les dimensions originales de l'image
        const originalWidth = img.width;
        const originalHeight = img.height;

        // Créer un canvas avec les dimensions originales
        const canvas = document.createElement("canvas");
        canvas.width = originalWidth;
        canvas.height = originalHeight;
        const ctx = canvas.getContext("2d");

        if (ctx === null) {
          reject(new Error("Impossible de récupérer le contexte du canvas"));
          return;
        }

        // Dessiner l'image originale sur le canvas
        ctx.drawImage(img, 0, 0, originalWidth, originalHeight);

        // Convertir le contenu du canvas en Blob
        pica()
          .toBlob(canvas, "image/jpeg", 0.9)
          .then((blob) => {
            resolve(blob);
          })
          .catch((err) => {
            reject(err);
          });
      };
      img.onerror = () => {
        reject(new Error("Erreur lors du chargement de l'image"));
      };
    });
  };

  return (
    <FormControl id={name}>
      <FormLabel>{label}</FormLabel>
      <VStack spacing={4}>
        <Box
          {...getRootProps()}
          w="100%"
          h="100px"
          border="2px dashed"
          borderColor={filePath ? "green.500" : "red.500"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          _hover={{ bg: "gray.100" }}
          cursor="pointer"
          role="button"
        >
          <input {...getInputProps()} name={name} />
          <Text padding={4}>
            {isLoading ? (
              <LoaderSpinner />
            ) : file && file[0] ? (
              `Fichier déposé : ${name}` // Afficher le nom du fichier
            ) : isDragActive ? (
              "Déposez le fichier ici..."
            ) : (
              "Glissez un fichier ici, ou cliquez pour sélectionner un fichier"
            )}
          </Text>
        </Box>
        <a
          href={filePath}
          target="_blank"
          download
          ref={downloadLinkRef}
          style={{ display: "none" }}
        ></a>
        {filePath && (
          <HStack spacing={4}>
            {userRole === Roles.Admin ? (
              <Button
                colorScheme="red"
                leftIcon={<Icon as={AiOutlineDelete} />}
                onClick={deleteFile}
              >
                Supprimer
              </Button>
            ) : (
              false
            )}
            <Button
              variant="secondaryButton"
              leftIcon={<Icon as={AiOutlineFile} />}
              onClick={() => {
                if (downloadLinkRef.current) {
                  downloadLinkRef.current.click();
                }
              }}
            >
              Voir le fichier
            </Button>
          </HStack>
        )}
      </VStack>
    </FormControl>
  );
};

export default DocumentUpload;
