import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  HStack,
  Input,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AuthContext } from "../../../../utils/context";
import { Roles } from "../../../../utils/types/roles.interface";
import { AddressForm } from "./AdressForm";
import { BirthPlaceForm } from "./BirthForm";
import UrgenceContact from "./UrgenceContact";

export interface CountryType {
  name: string;
  idependent: boolean;
}
const sizeShirt = ["XS", "S", "M", "L", "XL", "XXL"];
const sizePants = ["XS", "S", "M", "L", "XL", "XXL", "XXXL"];
const sizeVeste = ["32", "34", "36", "38", "40", "42", "44", "46", "48", "50"];

export interface InfosPersoProps {
  isMinor: boolean;
  setIsMinor: React.Dispatch<React.SetStateAction<boolean>>;
  securityChecked?: boolean; // Notez le '?' qui rend la propriété optionnelle
  siapChecked?: boolean;
  siap2Checked?: boolean;
  siap3Checked?: boolean;
}

const InfosPerso: React.FC<InfosPersoProps> = ({
  isMinor,
  setIsMinor,
  securityChecked,
  siapChecked,
  siap2Checked,
  siap3Checked,
}) => {
  const {
    register,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext(); // utilise useFormContext pour obtenir les méthodes de formulaire
  const birthDate = watch("birth.date_of_birth");
  const isStranger = watch("stranger");
  const [countries, setCountries] = useState([]);
  const { userRole } = useContext(AuthContext);
  const isVacataire = userRole === Roles.Vacataire;

  useEffect(() => {
    if (birthDate) {
      const eighteenYearsAgo = dayjs().subtract(18, "year").unix();
      setIsMinor(birthDate > eighteenYearsAgo);
    }
  }, [birthDate]);

  useEffect(() => {
    fetch("/others/country.json")
      .then((response) => response.json())
      .then((data) => {
        const countries = data.map((country: any) => country.name);
        setCountries(countries);
      })
      .catch((error) => console.error(error));
  }, []);
  return (
    <>
      <Box my={4}>
        {userRole === Roles.Admin && (
          <Grid
            templateColumns={{
              base: "1fr 1fr",
              md: "1fr 1fr",
              lg: "1fr 1fr",
            }}
            mt={4}
            gap={4}
            alignItems="center" // Aligne les éléments verticalement au centre
          >
            <FormControl id="serial_number">
              {/* Ajoutez la condition ici */}
              <FormLabel htmlFor="serial_number">Matricule E.T.M.S</FormLabel>
              <Input {...register("serial_number")} maxLength={5} />
              {errors.serial_number && (
                <FormErrorMessage>
                  Votre matricule doit avoir 5 chiffres
                </FormErrorMessage>
              )}{" "}
            </FormControl>
            <FormControl id="serial_number_hospitality">
              {/* Ajoutez la condition ici */}
              <FormLabel htmlFor="serial_number_hospitality">
                Matricule Troyes Hospitality
              </FormLabel>
              <Input {...register("serial_number_hospitality")} maxLength={6} />
              {errors.serial_number_hospitality && (
                <FormErrorMessage>
                  Votre matricule doit avoir 6 chiffres
                </FormErrorMessage>
              )}{" "}
            </FormControl>
          </Grid>
        )}
        {userRole === Roles.Admin && (
          <Stack spacing="4" direction="row" justifyContent="start" mt="6">
            {/* Aligne les éléments horizontalement au début */}
            <Checkbox size="md" {...register("security")} colorScheme="yellow">
              Agent de sécurité
            </Checkbox>
            <Checkbox size="md" {...register("siap")} colorScheme="yellow">
              SSIAP 1
            </Checkbox>
            <Checkbox size="md" {...register("siap2")} colorScheme="yellow">
              SSIAP 2
            </Checkbox>
            <Checkbox size="md" {...register("siap3")} colorScheme="yellow">
              SSIAP 3
            </Checkbox>
          </Stack>
        )}
        <Grid
          templateColumns={{
            base: "1fr",
            md: " 1fr 1fr",
            lg: "1fr 1fr",
          }}
          mt={4}
          gap={4}
        >
          <FormControl isInvalid={!!errors.name} isRequired={true}>
            <FormLabel htmlFor="name">Nom</FormLabel>
            <Input
              {...register("name", {
                onChange: (e) => {
                  const uppercaseValue = e.target.value.toUpperCase();
                  e.target.value = uppercaseValue;
                  return uppercaseValue; // retournez la nouvelle valeur si nécessaire
                },
              })}
              placeholder=""
              isDisabled={isVacataire}
              style={{ textTransform: "uppercase" }}
            />
          </FormControl>
          <FormControl isInvalid={!!errors.firstname} isRequired={true}>
            <FormLabel htmlFor="firstname">Prénom</FormLabel>
            <Input
              {...register("firstname", {
                onChange: (e) => {
                  const uppercaseValue = e.target.value.toUpperCase();
                  e.target.value = uppercaseValue;
                  return uppercaseValue; // retournez la nouvelle valeur si nécessaire
                },
              })}
              placeholder=""
              isDisabled={isVacataire}
              style={{ textTransform: "uppercase" }}
            />
          </FormControl>
        </Grid>
      </Box>
      {/* PANTS SIZE ECT*/}
      <Box my="4">
        <Grid
          templateColumns={{
            base: "1fr",
            md: "1fr 1fr",
            lg: "1fr 1fr",
          }}
          mt={4}
          gap={4}
        >
          <FormControl isInvalid={!!errors.mail} isRequired={true}>
            <FormLabel htmlFor="mail">Mail</FormLabel>
            <Input {...register("mail")} placeholder="" />
            {errors.mail && (
              <FormErrorMessage>
                {errors.mail?.message as string}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!errors.tel}>
            <FormLabel htmlFor="tel">Tel</FormLabel>
            <Input {...register("tel")} placeholder="" />
            {errors.tel && (
              <FormErrorMessage>
                {errors.tel?.message as string}
              </FormErrorMessage>
            )}
          </FormControl>
        </Grid>
        <FormControl
          isInvalid={!!errors.security_number}
          id="security_number"
          mt="4"
        >
          <FormLabel>Numéro de sécurité sociale</FormLabel>
          <Controller
            name="security_number"
            render={({ field }) => (
              <Input
                type="text"
                {...field}
                maxLength={15}
                inputMode="numeric"
                pattern="[0-9]*"
                onInput={(e) => {
                  e.currentTarget.value = e.currentTarget.value.replace(
                    /[^0-9]/g,
                    ""
                  );
                }}
              />
            )}
          />
          {errors.security_number && (
            <FormErrorMessage>
              {errors.security_number?.message as string}
            </FormErrorMessage>
          )}
        </FormControl>
      </Box>
      <FormControl id="gender">
        <FormLabel htmlFor="gender">Civilité</FormLabel>
        <Select {...register("gender")} placeholder="Sélectionner">
          <option value="M">Monsieur</option>
          <option value="F">Madame</option>
          <option value="autre">Autre</option>
        </Select>
      </FormControl>

      <Grid
        templateColumns={{
          base: "1fr",
          md: "1fr",
          lg: "1fr",
        }}
        gap={4}
        my={4}
      >
        <FormControl
          isInvalid={!!(errors.birth as any)?.date_of_birth}
          id="date_of_birth"
        >
          <FormLabel>Date de naissance</FormLabel>
          <Controller
            name="birth.date_of_birth"
            render={({ field: { onChange, value, ...field } }) => (
              <Input
                {...field}
                type="date"
                value={
                  value
                    ? new Date(value * 1000).toISOString().split("T")[0]
                    : ""
                }
                onChange={(event) => {
                  const date = new Date(event.target.value);
                  onChange(date.getTime() / 1000);
                }}
              />
            )}
          />

          {errors.birth && "date_of_birth" in errors.birth && (
            <FormErrorMessage>
              {errors.birth?.date_of_birth?.message as string}
            </FormErrorMessage>
          )}
        </FormControl>
      </Grid>

      <BirthPlaceForm />
      <Box mt="2">
        <FormControl id="nationality" mt={4}>
          <FormLabel>Nationalité</FormLabel>
          <Controller
            name="nationality"
            render={({ field }) => (
              <Select {...field} placeholder="Sélectionner la nationalité">
                {countries.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </Select>
            )}
          />
        </FormControl>

        {!isStranger && (
          <Box>
            <FormControl id="identity_num" mt={4}>
              <FormLabel>
                {" "}
                {!isMinor
                  ? "Numéro de carte d'identité"
                  : "Numéro de carte d'identité du mineur"}
              </FormLabel>
              <Input {...register("identity_num")} maxLength={12} />
            </FormControl>

            <Grid
              templateColumns={{
                base: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1fr",
              }}
              gap={4}
              mt={4}
            >
              <FormControl id="start_identity_num" mt={4}>
                <FormLabel>Date d'émission de la carte d'identité</FormLabel>
                <Controller
                  name="start_identity_num"
                  render={({ field: { onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      type="date"
                      value={
                        value
                          ? new Date(value * 1000).toISOString().split("T")[0]
                          : ""
                      }
                      onChange={(event) => {
                        const date = new Date(event.target.value);
                        onChange(date.getTime() / 1000);
                      }}
                    />
                  )}
                />
                {errors.start_identity_num && (
                  <FormErrorMessage>
                    {errors.start_identity_num?.message as string}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl id="end_identity_num" mt={4}>
                <FormLabel>Date d'expiration de la carte d'identité</FormLabel>
                <Controller
                  name="end_identity_num"
                  render={({ field: { onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      type="date"
                      value={
                        value
                          ? new Date(value * 1000).toISOString().split("T")[0]
                          : ""
                      }
                      onChange={(event) => {
                        const date = new Date(event.target.value);
                        onChange(date.getTime() / 1000);
                      }}
                    />
                  )}
                />
                {errors.end_identity_num && (
                  <FormErrorMessage>
                    {errors.end_identity_num?.message as string}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Grid>
          </Box>
        )}
        <FormControl id="stranger" mt={4}>
          <HStack>
            <Controller
              name="stranger"
              render={({ field }) => (
                <Checkbox
                  size="lg"
                  colorScheme="yellow"
                  {...field}
                  isChecked={isStranger}
                />
              )}
            />
            <Text fontSize="md">Etranger</Text>
          </HStack>
        </FormControl>
        {isStranger && (
          <Box>
            <FormControl id="stanger_num_resident" mt={4}>
              <FormLabel>
                N° du titre de séjour ou de l’autorisation de travail{" "}
              </FormLabel>
              <Input
                {...register("legal_resp.stanger_num_resident")}
                maxLength={9}
              />
            </FormControl>

            <Grid
              templateColumns={{
                base: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1fr",
              }}
              gap={4}
              mt={4}
            >
              <FormControl id="stranger_start_validity_date" mt={4}>
                <FormLabel>Date d'émission du titre de séjour</FormLabel>
                <Controller
                  name="legal_resp.stranger_start_validity_date"
                  render={({ field: { onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      type="date"
                      value={
                        value
                          ? new Date(value * 1000).toISOString().split("T")[0]
                          : ""
                      }
                      onChange={(event) => {
                        const date = new Date(event.target.value);
                        onChange(date.getTime() / 1000);
                      }}
                    />
                  )}
                />
              </FormControl>

              <FormControl id="stranger_end_validity_date" mt={4}>
                <FormLabel>Date d'expiration du titre de séjour</FormLabel>
                <Controller
                  name="legal_resp.stranger_end_validity_date"
                  render={({ field: { onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      type="date"
                      value={
                        value
                          ? new Date(value * 1000).toISOString().split("T")[0]
                          : ""
                      }
                      onChange={(event) => {
                        const date = new Date(event.target.value);
                        onChange(date.getTime() / 1000);
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Box>
        )}
        {(securityChecked || siapChecked || siap2Checked || siap3Checked) && (
          <Box>
            <Text fontSize="1.5xl" fontFamily="montserrat" fontWeight="bold">
              Profil sécurité
            </Text>
            <Grid
              templateColumns={{
                base: "1fr",
                md: " 1fr 1fr",
                lg: "1fr 1fr",
              }}
              mt={4}
              gap={4}
            >
              <FormControl id="diplome_deliverance">
                <FormLabel>
                  {securityChecked
                    ? "Date de délivrance de l’agrément"
                    : "Date de délivrance du diplôme"}
                </FormLabel>
                <Controller
                  name="diplome_deliverance"
                  render={({ field: { onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      type="date"
                      value={
                        value
                          ? new Date(value * 1000).toISOString().split("T")[0]
                          : ""
                      }
                      onChange={(event) => {
                        const date = new Date(event.target.value);
                        onChange(date.getTime() / 1000);
                      }}
                    />
                  )}
                />
              </FormControl>
              {(siapChecked ||
                siap2Checked ||
                siap3Checked ||
                securityChecked) &&
                userRole === Roles.Admin && (
                  <FormControl id="end_diplome_deliverance">
                    <FormLabel>
                      {" "}
                      {securityChecked
                        ? "Date de fin de validité de l’agrément"
                        : "Date de fin de validité"}
                    </FormLabel>
                    <Controller
                      name="end_diplome_deliverance"
                      render={({ field: { onChange, value, ...field } }) => (
                        <Input
                          {...field}
                          type="date"
                          value={
                            value
                              ? new Date(value * 1000)
                                  .toISOString()
                                  .split("T")[0]
                              : ""
                          }
                          onChange={(event) => {
                            const date = new Date(event.target.value);
                            onChange(date.getTime() / 1000);
                          }}
                        />
                      )}
                    />
                  </FormControl>
                )}
            </Grid>
          </Box>
        )}
        {(siapChecked || siap2Checked || siap3Checked || securityChecked) &&
          userRole === Roles.Admin && (
            <FormControl id="number_diplome" mt="4">
              <FormLabel>
                {" "}
                {securityChecked
                  ? "N° de la carte professionnelle"
                  : "N° du diplôme"}
              </FormLabel>

              <Input {...register("number_diplome")} type="text" />
            </FormControl>
          )}
        <Box mt="2">
          {/* Champs responsable légal si mineur */}
          {isMinor && (
            <Box>
              <Text fontSize="1.5xl" fontFamily="montserrat" fontWeight="bold">
                Reponsable légal
              </Text>
              <Grid
                templateColumns={{
                  base: "1fr",
                  md: "1fr 1fr",
                  lg: "1fr 1fr",
                }}
                gap={4}
                mt={4}
              >
                <FormControl id="legal_resp_name" isRequired>
                  <FormLabel>Nom du responsable légal</FormLabel>
                  <Controller
                    name="legal_resp.resp_name"
                    render={({ field }) => <Input {...field} />}
                  />
                </FormControl>
                <FormControl id="legal_resp_firstname" isRequired>
                  <FormLabel>Prénom du responsable légal</FormLabel>
                  <Controller
                    name="legal_resp.resp_firstname"
                    render={({ field }) => <Input {...field} />}
                  />
                </FormControl>
              </Grid>

              <Grid
                templateColumns={{
                  base: "1fr",
                  md: "1fr 1fr",
                  lg: "1fr 1fr",
                }}
                gap={4}
                mt={4}
              >
                <FormControl id="legal_resp_tel">
                  <FormLabel>Téléphone du responsable légal</FormLabel>
                  <Controller
                    name="legal_resp.resp_tel"
                    render={({ field }) => <Input {...field} />}
                  />
                </FormControl>
                <FormControl id="legal_resp_mail">
                  <FormLabel>Email du responsable légal</FormLabel>
                  <Controller
                    name="legal_resp.resp_mail"
                    render={({ field }) => <Input {...field} />}
                  />
                </FormControl>
              </Grid>
              <Text fontSize="1.5xl" fontFamily="montserrat" fontWeight="bold">
                Adresse du responsable légal
              </Text>
              <AddressForm formPrefix="legal_resp" />
            </Box>
          )}
        </Box>
        <UrgenceContact />
      </Box>
    </>
  );
};

export default InfosPerso;
