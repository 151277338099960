import { Box, Container, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query"; // Importez useMutation et useQueryClient
import { useLocation } from "react-router-dom";
import { API_URL, FILECONTRACT, ZONEVACATAIRE } from "../../api";
import { useAuth } from "../../components/Authentification/useAuth";
import {
  STATUS_ACCEPT,
  STATUS_REFUSE,
} from "../../components/Evenements/zones/EvenementZones";
import { LoaderSpinner } from "../../components/_shared/loader/CustomSpinner";

const API_ENDPOINT = `${API_URL}${ZONEVACATAIRE}`;
const API_FILE = `${API_URL}${FILECONTRACT}`;

const UpdateStatus: React.FC = () => {
  const location = useLocation();
  const { isLoggedIn } = useAuth();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [message, setMessage] = React.useState<string>("");
  const [actualStatus, setActualStatus] = React.useState<string | null>(null);
  const queryParams = new URLSearchParams(location.search);
  const responseStatus = queryParams.get("responsesStatus");
  const eventId = queryParams.get("eventId");
  const serviceName = queryParams.get("serviceName");
  const zoneName = queryParams.get("zoneName");
  const vacataireId = queryParams.get("vacataireId");
  const firstname = queryParams.get("firstname");
  const type = queryParams.get("type");
  const eventName = queryParams.get("eventName");
  const hasMutated = useRef(false);
  const [isGeneratingFile, setIsGeneratingFile] = React.useState(false);
  console.log(actualStatus);
  const fetchVacataireStatus = async (): Promise<string> => {
    const response = await fetch(
      `${API_ENDPOINT}/${eventId}/${serviceName}/${vacataireId}/status`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch status");
    }

    const data = await response.json();
    return data.status;
  };

  const {
    data: fetchedStatus,
    isError,
    isLoading,
  } = useQuery(
    ["vacataireStatus", eventId, serviceName, vacataireId],
    fetchVacataireStatus
  );

  const generateSignFileMutation = useMutation(
    async () => {
      setMessage(
        "Votre fichier est en cours de génération, veuillez ne pas quitter la page."
      );
      setIsGeneratingFile(true);
      const response = await fetch(
        `${API_FILE}/${eventId}/${serviceName}/${vacataireId}/sign`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to generate and sign file");
      }
    },
    {
      onSuccess: () => {
        setIsGeneratingFile(false);
        setMessage(
          "Votre réponse a bien été enregistrée, vous allez recevoir d'ici quelques instants un email pour signer votre contrat."
        );
        toast({
          title: "Fichier généré avec succès",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      },
      onError: () => {
        setIsGeneratingFile(false); // Arrêtez le spinner en cas d'erreur
        setMessage(
          "Erreur lors de la génération et de la signature du fichier. Réessayez plus tard ou contactez un membre ETMS."
        );
        toast({
          title:
            "Erreur lors de la génération et de la signature du fichier. Réessayez plus tard ou contactez un membre ETMS.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );
  const sendEmailMutation = useMutation(
    async () => {
      const response = await fetch(`${API_ENDPOINT}/send-confirm-refuse`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          eventId,
          eventName,
          serviceName,
          zoneName,
          vacataireId,
          firstname,
          type,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send email");
      }
    },
    {
      onError: () => {
        toast({
          title:
            "Erreur lors de l'envoi de l'email. Réessayez plus tard ou contactez un membre ETMS.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  const mutation = useMutation(
    async () => {
      const response = await fetch(
        `${API_ENDPOINT}/${eventId}/${serviceName}/${zoneName}/vacataires/${vacataireId}/status-update`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: responseStatus }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update status");
      }
    },
    {
      onSuccess: () => {
        const notificationMessage =
          responseStatus === STATUS_ACCEPT
            ? "Votre présence a bien été confirmée."
            : "Votre indisponibilité a bien été enregistré.";

        toast({
          title: "Statut mis à jour",
          description: notificationMessage,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        queryClient.invalidateQueries("someQueryKey"); // Remplacez 'someQueryKey' par une clé appropriée si nécessaire
      },
      onError: () => {
        toast({
          title:
            "Erreur lors de la mise à jour du status. Réessayez plus tard ou contactez un membre ETMS.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  useEffect(() => {
    if (!isLoading && !isError) {
      setActualStatus(fetchedStatus || null);
    }
  }, [fetchedStatus, isLoading, isError]);

  useEffect(() => {
    if (isLoggedIn && actualStatus && !hasMutated.current) {
      if (
        actualStatus === STATUS_ACCEPT ||
        actualStatus === STATUS_REFUSE ||
        actualStatus === null
      ) {
        setMessage("Vous avez déjà répondu à la convocation.");
        return;
      }

      if (!hasMutated.current) {
        mutation.mutate();
        if (responseStatus) {
          if (
            responseStatus !== STATUS_ACCEPT &&
            actualStatus !== STATUS_ACCEPT &&
            actualStatus !== STATUS_REFUSE
          ) {
            sendEmailMutation.mutate();
            setMessage("Votre indisponibilité a bien été enregistrée.");
          } else if (
            responseStatus === STATUS_ACCEPT &&
            actualStatus !== STATUS_ACCEPT &&
            actualStatus !== STATUS_REFUSE
          ) {
            generateSignFileMutation.mutate();
            setMessage("Génération de votre contrat en cours...");
          }
        }

        hasMutated.current = true;
      }
    }
  }, [
    isLoggedIn,
    mutation,
    responseStatus,
    sendEmailMutation,
    generateSignFileMutation,
    actualStatus,
  ]);

  useEffect(() => {
    if (!isGeneratingFile && responseStatus === STATUS_ACCEPT && actualStatus) {
      if (
        actualStatus === STATUS_ACCEPT ||
        actualStatus === STATUS_REFUSE ||
        actualStatus === null
      ) {
        return;
      }
      setMessage(
        "Votre réponse a bien été enregistrée, vous allez recevoir d'ici quelques instants un email pour signer votre contrat."
      );
    }
  }, [isGeneratingFile, actualStatus]);

  return (
    <Container py={{ base: "4", md: "12" }} px={{ base: "0", md: 24 }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        {isGeneratingFile ? (
          <>
            <LoaderSpinner />
            <Text mt={4} fontSize="lg" textAlign="center">
              {message}
            </Text>
          </>
        ) : (
          <Text fontSize="xl" textAlign="center">
            {message}
          </Text>
        )}
      </Box>
    </Container>
  );
};

export default UpdateStatus;
