import { DownloadIcon } from "@chakra-ui/icons"; // Exemple d'icône
import {
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { API_URL, ZONEVACATAIRE } from "../../../api";
import { AnomalieDetails } from "../../../utils/types/EventType";
import { LoaderSpinner } from "../../_shared/loader/CustomSpinner";
import { AnomaliesTable } from "../../_shared/table/AnomaliesTable";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  eventId: string | null;
  vacataireId?: string | null;
};

export const DpaeModal: React.FC<Props> = ({
  isOpen,
  onClose,
  eventId,
  vacataireId,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [isTested, setIsTested] = useState(false);

  const [generationResults, setGenerationResults] = useState<{
    reussies: number;
    echouees: number;
    anomalies: AnomalieDetails[];
  } | null>(null);
  const [error, setError] = useState("");

  const handleGenerateDPAE = async (mode: number) => {
    setIsGenerating(true);
    setIsTested(false);
    setError("");
    if (!eventId) {
      setError("Aucun eventId fourni");
      setIsGenerating(false);
      return;
    }

    try {
      const url = `${API_URL}${ZONEVACATAIRE}/${eventId}/submit-dpae?mode=${mode}${
        vacataireId ? `&vacataireId=${vacataireId}` : ""
      }`;
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Échec de la génération des DPAE");
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const responseData = await response.json();
        setGenerationResults(responseData);
        console.log("Résultats de la génération des DPAE:", responseData);
        setIsTested(true);
      } else {
        console.log("DPAE générées avec succès, aucune donnée retournée.");
        setIsTested(true);
      }
    } catch (error: any) {
      console.error("Erreur lors de la génération des DPAE:", error);
      setError(error.message);
    } finally {
      setIsGenerating(false);
      setIsTested(true);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setGenerationResults(null);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        mx={{ base: 4, md: 15 }}
        maxW={{ base: "95vw", md: "65vw" }}
      >
        <ModalHeader>
          <HStack spacing={4}>
            <Text>
              {vacataireId ? "Génération de la DPAE" : "Génération des DPAE"}
            </Text>
            <Tooltip label="Télécharger les AR" aria-label="Télécharger les AR">
              <IconButton
                icon={<DownloadIcon />}
                aria-label="Télécharger les AR"
                isDisabled
              />
            </Tooltip>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isGenerating ? (
            <LoaderSpinner />
          ) : generationResults ? (
            <VStack align="start" spacing={4}>
              <Text color="green">{`Déclarations réussies : ${generationResults.reussies}`}</Text>
              <Text
                color={"red"}
              >{`Déclarations échouées : ${generationResults.echouees}`}</Text>
              <AnomaliesTable anomalies={generationResults.anomalies} />
            </VStack>
          ) : (
            <Text>
              Êtes-vous sûr de vouloir procéder à la génération des DPAE ?
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button colorScheme="gray" onClick={onClose}>
              Annuler
            </Button>
            <Button
              variant="primaryButton"
              onClick={() => handleGenerateDPAE(120)}
            >
              Générer les DPAE
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
