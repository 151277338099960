import {
  AddIcon,
  AttachmentIcon,
  ChevronDownIcon,
  DownloadIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createEvent,
  deleteEventCoop,
  getEvents,
  updateEvent,
  updateEventCoop,
} from "../../api/ApiEvenement";

import { EvenementType } from "../../utils/types/EventType";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { EmptyState } from "../_shared/state/EmptyState";
import { EventTable } from "./EventTable";
import CreateEditEvent from "./modal/CreateEditEvenements";
import DeleteEventModal from "./modal/DeleteEventModal";
import { DpaeModal } from "./modal/DpaeModal";
import ServicesModal from "./modal/ServicesModal";
type OptionType = {
  label: string;
  value: string;
};
export const ManageEvent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { eventType } = useParams();

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const fetchEvent = async () => {
    const data = await getEvents(eventType ? eventType : "");
    console.log(data);
    return data;
  };

  const {
    data: event,
    isError,
    error,
    refetch,
  } = useQuery<EvenementType[], Error>("event", fetchEvent);

  const pageCount = event ? Math.ceil(event.length / itemsPerPage) : 0;

  const handlePageClick = (data: { selected: number }) => {
    setCurrentPage(data.selected);
  };
  const paginatedData = event
    ? event.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
    : [];

  const imagePath = "/images/empty_state.png";
  const queryClient = useQueryClient();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<string | null>(null);
  const [eventToDpae, setEventToDpae] = useState<string | null>(null);

  const openDeleteModal = (eventId: string) => {
    setEventToDelete(eventId);
    setDeleteModalOpen(true);
  };

  const [editing, setEditing] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<
    Partial<EvenementType> | undefined
  >(undefined);

  const isDataEmpty = event?.length === 0 || event?.length === undefined;
  const toast = useToast();

  const {
    isOpen: isServicesOpen,
    onOpen: onOpenServiceModal,
    onClose: onCloseServicesModal,
  } = useDisclosure();

  const {
    isOpen: isDpaeOpen,
    onOpen: onOpenDpaeModal,
    onClose: onCloseDpaeModal,
  } = useDisclosure();

  const updateCoopMutation = useMutation<
    EvenementType,
    Error,
    { eventId: string; newCoop: { _id: string; name: string } }
  >(({ eventId, newCoop }) => updateEventCoop(eventId, newCoop), {
    // newCoop est ajouté comme second argument pour le callback onSuccess
    onSuccess: (updatedEvent, variables) => {
      // Rafraîchir les données après la mise à jour réussie
      refetch();
      toast({
        title: "Service ajoutée",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
    onError: (error) => {
      toast({
        title: "An error occurred.",
        description: `Error: ${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleSubmitCoop = (newCoopData: { _id: string; name: string }) => {
    if (selectedEvent?._id) {
      const eventData = {
        eventId: selectedEvent._id,
        newCoop: newCoopData,
      };
      updateCoopMutation.mutate(eventData);
    }
  };

  const handleDeleteCoop = (eventId: string, serviceId: string) => {
    if (selectedEvent?._id) {
      const eventData = {
        eventId: eventId,
        coopId: serviceId,
      };
      console.log(eventData);
      deleteCoopMutation.mutate(eventData);
    }
  };

  const openServiceModal = (event: EvenementType) => {
    setSelectedEvent(event);
    onOpenServiceModal();
  };

  const openDpaeModal = (eventId: string) => {
    setEventToDpae(eventId);
    onOpenDpaeModal();
  };
  /*
  const handleListModal = async () => {
    if (!selectedEvent) return;
    try {
      await listVacEvent(selectedEvent);
      onCloseListModal();
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const openListModal = (event: EvenementType) => {
    setSelectedEvent(event);
    onListOpenModal();
  };
  */

  const handleSubmitEvent = (data: EvenementType) => {
    try {
      if (selectedEvent) {
        updateMutation.mutate(data);
      } else {
        createMutation.mutate(data);
      }
      onClose();
    } catch (error) {
      console.error(error);
    }
  };
  const deleteCoopMutation = useMutation<
    EvenementType,
    Error,
    { eventId: string; coopId: string } // Remarquez que c'est maintenant coopId et non serviceId
  >(deleteEventCoop, {
    onSuccess: (updatedEvent, variables) => {
      refetch();
      toast({
        title: "Service supprimé",
        description: `Le service a été supprimé avec succès.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
    onError: (error) => {
      toast({
        title: "Une erreur s'est produite.",
        description: `Erreur : ${error.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    },
  });

  /*
  const assignMutation = useMutation(saveVacatairesToEvent, {
    onSuccess: () => {
      toast({
        title: "Succès.",
        description: "Vacataires ajoutés au listing avec succès.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onCloseListModal();
    },
    onError: (error) => {
      toast({
        title: "Erreur.",
        description: "Erreur lors de l’ajout des vacataires au listing.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    },
  });


  const handleSubmitAssign = (data: any) => {
    //assignMutation.mutate(data);
    toast({
      title: "Succès.",
      description: "(non activé) Vacataires ajoutés au listing avec succès.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onCloseListModal();
  };
        */

  const createMutation = useMutation(createEvent, {
    onSuccess: (data) => {
      refetch();

      toast({
        title: "Succès",
        description: "L'événement a été créé avec succès.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    },
    onError: (error) => {
      console.error(error);

      // Afficher un toast d'erreur
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la création de l'événement.",
        status: "error",
        duration: 5000, // Afficher le toast pendant 5 secondes
        isClosable: true, // Permettre à l'utilisateur de fermer le toast manuellement
        position: "top-right", // Position du toast
      });
    },
  });

  const updateMutation = useMutation(updateEvent, {
    onSuccess: (data) => {
      refetch();
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const openCreateEvent = () => {
    setSelectedEvent(undefined);
    onOpen();
  };

  const openEditEvent = (event: EvenementType) => {
    setSelectedEvent(event);
    onOpen();
  };

  //Export
  const exportPDF = () => {
    // Votre logique d'exportation PDF ici
    if (event) {
      const doc = new jsPDF();
      autoTable(doc, { html: "#my-table-id" });
      doc.save("vacataires.pdf");
    }
  };

  const exportCSV = () => {
    // Votre logique d'exportation CSV ici
    if (event) {
      const csv = Papa.unparse(event);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "vacataires.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const exportExcel = () => {
    // Votre logique d'exportation Excel ici
    if (event) {
      const ws = XLSX.utils.json_to_sheet(event);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Evenement");
      XLSX.writeFile(wb, "evenement.xlsx");
    }
  };

  return (
    <Box py={{ base: "4", md: "8" }} px={{ base: "0", md: 2 }}>
      <Box>
        <Stack>
          <Box>
            <Box py="12">
              <Flex justify="space-between" align="center" py="1">
                <Heading
                  size={useBreakpointValue({ base: "xs", md: "sm" })}
                  color="primary"
                >
                  Gestion des évènements{" "}
                  {eventType ? eventType.toLowerCase() : ""}
                </Heading>
                <Stack isInline spacing={2}>
                  <Button
                    ml={4}
                    leftIcon={<AddIcon />}
                    variant={"outline"}
                    fontWeight="medium"
                    borderColor="primary"
                    color="primary"
                    onClick={openCreateEvent}
                  >
                    Nouvel évènement
                  </Button>
                  {event && (
                    <Menu>
                      <MenuButton
                        as={Button}
                        color="primary"
                        rightIcon={<ChevronDownIcon />}
                      ></MenuButton>
                      <MenuList>
                        <MenuItem icon={<AttachmentIcon />} onClick={exportPDF}>
                          PDF
                        </MenuItem>
                        <MenuItem icon={<DownloadIcon />} onClick={exportCSV}>
                          CSV
                        </MenuItem>
                        <MenuItem icon={<AddIcon />} onClick={exportExcel}>
                          Excel
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </Stack>
              </Flex>
              <Text color="muted">
                Sur cette page, gérez vos différents évènements
              </Text>
            </Box>
            <Box
              boxShadow={{ base: "xlg", md: "xlg" }}
              borderRadius={{ base: "none", md: "lg" }}
            >
              <Stack spacing="5">
                {isDataEmpty ? (
                  <EmptyState
                    onButtonClick={openCreateEvent}
                    imagePath={imagePath}
                    title="Aucun évènement trouvé"
                    description="Il semble que vous n'ayez pas encore d'évènement. Créez-en un pour commencer à profiter des fonctionnalités de l'application."
                    textButton="Créer un nouvel évènement"
                  />
                ) : (
                  <Flex
                    justify="space-between"
                    align="center"
                    py="1"
                    maxWidth="100%"
                  >
                    <EventTable
                      data={paginatedData}
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      openEditEvent={openEditEvent}
                      openServiceModal={openServiceModal}
                      openDpaeModal={openDpaeModal}
                      deleteCoop={handleDeleteCoop}
                      openDeleteModal={openDeleteModal}
                    />
                  </Flex>
                )}
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Box>
      <CreateEditEvent
        selectedEvent={selectedEvent}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmitEvent}
      />
      <ServicesModal
        isOpen={isServicesOpen}
        onClose={onCloseServicesModal}
        onSubmit={handleSubmitCoop}
      />
      <DeleteEventModal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        eventId={eventToDelete}
        onDeleteSuccess={() => {
          // Invalider la requête pour recharger les données
          queryClient.invalidateQueries("event");

          // Afficher un toast de succès
          toast({
            title: "Événement supprimé",
            description: `L'événement a été supprimé avec succès.`,
            status: "success",
            duration: 3000,
            isClosable: true,
          });

          // Fermer la modal
          setDeleteModalOpen(false);
        }}
      />
      <DpaeModal
        isOpen={isDpaeOpen}
        onClose={onCloseDpaeModal}
        eventId={eventToDpae}
      />
    </Box>
  );
};
