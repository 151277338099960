import { RepeatIcon } from "@chakra-ui/icons";
import { Box, ChakraProvider, IconButton } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import customTheme from "./theme";
import AppRouter from "./utils/navigation/routes";
import { AuthProvider } from "./utils/provider/AuthProvider";

const queryClient = new QueryClient();

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <ChakraProvider theme={customTheme}>
        <Box position="fixed" top={2} right={5} zIndex="modal">
          <IconButton
            aria-label="Rafraîchir la page"
            icon={<RepeatIcon />}
            color="primary"
            onClick={() => {
              const currentUrl = window.location.href.split("?")[0]; // Retire les paramètres de requête existants
              const newUrl = `${currentUrl}?refresh=${new Date().getTime()}`; // Ajoute un paramètre de requête unique
              window.location.href = newUrl; // Redirige vers la nouvelle URL
              window.location.reload(); // Recharge la page
            }}
          />
        </Box>
        <AppRouter />
      </ChakraProvider>
    </AuthProvider>
  </QueryClientProvider>
);
