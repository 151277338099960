import { EvenementType } from "../utils/types/EventType";
import {
  API_EVENEMENT,
  API_PLACE,
  API_URL,
  REACT_APP_API_LISTING,
} from "./ApiPaths";
import { ERROR_UNKNOWN } from "./auth";

/** Get Events */
export const getEvents = async (eventType: string) => {
  const url = eventType
    ? `${API_URL}${API_EVENEMENT}?type=${eventType}`
    : `${API_URL}${API_EVENEMENT}`;

  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};

export const getPlace = async () => {
  const res = await fetch(API_URL + API_PLACE);
  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  return res.json();
};

/** Get One Event */
export const getOneEvent = async (id: string) => {
  return fetch(API_URL + API_EVENEMENT + "/" + id, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};
/** Create Event */
export const createEvent = async (data: EvenementType) => {
  return fetch(API_URL + API_EVENEMENT, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};

/** Update Event */
export const updateEvent = async (data: EvenementType) => {
  return fetch(API_URL + API_EVENEMENT + "/" + data._id, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};

export const listVacEvent = async (data: EvenementType) => {
  return fetch(API_URL + API_EVENEMENT + "/" + data._id, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};

/** Listing */
export const saveVacatairesToEvent = async (data: any) => {
  const response = await fetch(API_URL + REACT_APP_API_LISTING, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const message = await response.text();
    throw new Error(message);
  }

  return response.json();
};

// ApiEvenement.js

export const updateEventCoop = async (
  eventId: string,
  newCoop: { _id: string; name: string }
): Promise<EvenementType> => {
  const response = await fetch(`${API_URL}${API_EVENEMENT}/${eventId}/coop`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newCoop),
  });

  if (!response.ok) {
    throw new Error("Could not update coop");
  }

  const updatedEvent = await response.json();
  return updatedEvent;
};

export const deleteEventCoop = async ({
  eventId,
  coopId,
}: {
  eventId: string;
  coopId: string;
}): Promise<EvenementType> => {
  const response = await fetch(
    `${API_URL}${API_EVENEMENT}/${eventId}/coop/${coopId}`,
    {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Could not delete coop");
  }

  const responseData = await response.json();

  return responseData as EvenementType;
};

export const deleteEvent = async (eventId: string) => {
  const response = await fetch(`${API_URL}${API_EVENEMENT}/${eventId}`, {
    method: "DELETE",
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Error deleting event");
  }
};

/** Get Presence and Dpae Stats */
export const getPresenceAndDpaeStats = async (eventId: string) => {
  return fetch(`${API_URL}/zones-vacataire/event/${eventId}/presence-dpae`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};

/** Get All Presence and Dpae Stats events */
export const getAllPresenceAndDpaeStats = async () => {
  return fetch(`${API_URL}/zones-vacataire/events/stats`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};
/** Get All Presence and Dpae Stats service */
export const getPresenceAndDpaeStatsByService = async (
  eventId: string,
  serviceName: string
) => {
  return fetch(
    `${API_URL}/zones-vacataire/service/stats/${eventId}/${serviceName}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};
