import {
  Button,
  Center,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { FaFutbol, FaStreetView } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ContentContainer from "../../components/_shared/ContentContainer";
import { AuthContext } from "../../utils/context";
import { Roles } from "../../utils/types/roles.interface";

const SelectTypesView: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = (type: string) => {
    navigate(`/evenements/${type}`);
  };
  const { userRole } = useContext(AuthContext);

  return (
    <ContentContainer fullWidth>
      <Tabs size="md" variant="enclosed" color="primary">
        <TabList>
          <Tab fontWeight="bold">2023/2024</Tab>
        </TabList>
        <TabPanels>
          <Center height="100vh">
            <Flex justifyContent="center" mb={4}>
              <Button
                variant="primaryButton"
                leftIcon={<Icon as={FaFutbol} />}
                onClick={() => handleNavigation("MATCHS")}
                fontSize="xl"
                p={8}
                mr={8}
              >
                Matchs
              </Button>
              <Button
                disabled={userRole == Roles.LimitedAdmin ? true : false}
                variant="primaryButton"
                leftIcon={<Icon as={FaStreetView} />}
                onClick={() => handleNavigation("Hors match")}
                fontSize="xl"
                p={8}
              >
                Hors Matchs
              </Button>
            </Flex>
          </Center>
        </TabPanels>
      </Tabs>
    </ContentContainer>
  );
};

export default SelectTypesView;
