import { Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { LogoEstac } from "../logo";

export const SideNavPublic = () => {
  return (
    <Flex
      direction="column"
      px={{ base: "4", md: "8" }}
      height="full"
      color="on-accent"
    >
      <Flex align="center" h="24">
        <LogoEstac />
        <Heading as="h2" fontFamily="mono" size="md" ml="3">
          Events
        </Heading>
      </Flex>
      <Flex flex="1" align="center">
        <Stack spacing="8">
          <Stack spacing="6">
            <Heading as="h1" size={{ md: "lg", xl: "lg" }}>
              Application de gestion des vacataires
            </Heading>
            <Text fontSize="lg" maxW="md" fontWeight="medium">
              Connectez-vous et optimisez la gestion de vos documents,
              évènements dès maintenant.{" "}
            </Text>
          </Stack>
        </Stack>
      </Flex>
      <Flex align="center" h="24">
        <Text color="on-accent-subtle" fontSize="sm">
          © {new Date().getFullYear()} Estac. Tous droits reservés.
        </Text>
      </Flex>
    </Flex>
  );
};
